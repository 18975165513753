import React, { Component } from "react";
import PaypalExpressBtn from 'react-paypal-express-checkout';
export default class PaypalCheckout extends Component {
	
    render() {
        const onSuccess = (payment) => {
            // Congratulation, it came here means everything's fine!
            		//console.log("The payment was succeeded!", payment);
					this.props.payPalSuccess(payment);
            		// You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
        }
 
        const onCancel = (data) => {
			this.props.payPalSuccess('cancel');
            // User pressed "cancel" or close Paypal's popup!
            console.log('The payment was cancelled!', data);
            // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
        }
 
        const onError = (err) => {
            // The main Paypal's script cannot be loaded or somethings block the loading of that script!
            console.log("Error!", err);
            this.props.payPalSuccess('error');
            // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
            // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
			                      /*  alert(
									this.props.cartStore.menuStore.translation.payment_status,
									this.props.cartStore.menuStore.translation.payment_msg,
									[
									{
									text: this.props.cartStore.menuStore.translation.close
									}
									]
									);*/
        }
 
        //let env = 'sandbox'; // you can set here to 'production' for production
        //let currency = 'SGD'; // or you can set this value from your props or state
        //let total = 1; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
        // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/
 
        /*const client = {
            sandbox:    'AQXX0UTIFr22xWKGuYZbQlPo5bI0b4mq6sJrsZtGsEcjazjWSQFnAEaiMG4wj1-irtEimsyzLNCYDQw1',
            production: 'AQXX0UTIFr22xWKGuYZbQlPo5bI0b4mq6sJrsZtGsEcjazjWSQFnAEaiMG4wj1-irtEimsyzLNCYDQw1',
        }*/
        // In order to get production's app-ID, you will have to send your app to Paypal for approval first
        // For sandbox app-ID (after logging into your developer account, please locate the "REST API apps" section, click "Create App"):
        //   => https://developer.paypal.com/docs/classic/lifecycle/sb_credentials/
        // For production app-ID:
        //   => https://developer.paypal.com/docs/classic/lifecycle/goingLive/
 
        // NB. You can also have many Paypal express checkout buttons on page, just pass in the correct amount and they will work!
		//console.log(this.props.env,'cll',this.props.client,'tttt',this.props.total);
		console.log(this.props.options);
        return (
            <PaypalExpressBtn env={this.props.env} client={this.props.client} currency={this.props.currency} total={this.props.total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} paymentOptions={this.props.options}/>
        );
    }
}