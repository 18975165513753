import React, { Component } from "react";
import "./AddonUI.css"; // Importing CSS file

class AddonUI extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let functionality = null;
        switch (this.props.uiType) {
            case "checkbox":
                functionality = (
                    <input
                        type="checkbox"
                        onChange={this.props.onCheck}
                        checked={this.props.quantity > 0}
                    />
                );
                break;
            case "radio":
                functionality = (
                    <input
                        type="radio"
                        onChange={this.props.onCheck}
                        checked={this.props.quantity > 0}
                    />
                );
                break;
            default:
                functionality = (
                    <div className="cart-item-controls">
                        <button className="btn minus" disabled={this.props.disabled || this.props.quantity == 0} onClick={() => {
                            let qty = this.props.quantity;
                            this.props.onChange(--qty);
                        }}>-</button>
                        <span className="cart-item-quantity">{this.props.quantity}</span>
                        <button className="btn plus" disabled={this.props.disabled || this.props.quantity == this.props.max} onClick={() => {
                            let qty = this.props.quantity;
                            this.props.onChange(++qty);
                        }}>+</button>
                    </div>
                );
                break;
        }

        return (
            <div className="AddonUI cart-item">
                {this.props.enableImage ? (
                    <div className="cart-item-image">
                    <img
                        src={this.props.thumb} // Replace with actual image URL
                        alt={this.props.title}
                    />
                </div>
                ): null}
                <div className="cart-item-details">
                    <p className="cart-item-name">{this.props.title}</p>
                    <p className="cart-item-price">{this.props.price}</p>
                </div>
                {functionality}
            </div>
        );
    }
}

export default AddonUI;
