import { observable, action, computed } from "mobx";
import API from "../libs/api";
import moment from "moment";
import _ from "underscore";

const controllerID = (() => {
  return window.location.pathname.split("/")[1];
})();

const ORDER_SAVE_KEY = `${controllerID}_order`;

class Order {
  id;
  @observable timestamp;
  @observable status;
  @observable info;
  @observable cartEntries;

  constructor({ id, status, timestamp, info,cartEntries,orderNo,table,spotOrderId,pingUrl,isNew,priceReceipt}) {
    this.id = id;
    this.status = status;
    this.timestamp = timestamp;
    this.info = info;
    this.cartEntries = cartEntries;
    this.orderNo=orderNo;
    this.table=table;
    this.spotOrderId=spotOrderId;
    this.pingUrl=pingUrl;
    this.isNew=isNew;
    this.priceReceipt=priceReceipt;
  }

  @computed get time() {
    return moment(this.timestamp).format("DD/MM/YYYY H:mm:ss");
  }

  @computed get paymentStatus() {
    if (this.status === null) return "Pending";

    if (this.status === true) {
      return "Success";
    } else if (this.status === false) {
      return "Error";
    } else return this.status;
  }
}

class OrderStore {
  @observable.shallow lists = [];
  translation;
  // A function intialized in Orders.js
  // Handles the popup and receives the information of the checkout response
  orderUpdateHook;

  constructor(translation) {
    // Load from localStorage
    const orders = localStorage.getItem(ORDER_SAVE_KEY);
    if (orders) {
      if (orders !== "undefined") {
        const jsonOrders = JSON.parse(orders);

        this.lists = jsonOrders.map(order => new Order({ ...order }));
      }
    }
    this.translation = translation;
  }

  @computed get isEmpty() {
    return this.lists.length > 0 ? false : true;
  }

  @computed get reversedList() {
    return this.lists.reverse();
  }

  @action addOrder({ id, status,cartEntries,orderNo,table,spotOrderId,pingUrl,priceReceipt}) {
    this.lists.push(
      new Order({
        id: id,
        status: status,
        timestamp: new Date().toISOString(),
        info: "",
        cartEntries:cartEntries,
        orderNo:orderNo,
        table:table,
        spotOrderId:spotOrderId,
		pingUrl:pingUrl,
		isNew:true,
		priceReceipt
      })
    );
    this.saveOrders();
  }
   @action checkOrder(order) {
	   console.log(order.pingUrl);
	 if(order.pingUrl && order.pingUrl!=="")
	 {
   
   return API.getPingStaus(order.pingUrl)
      .then(res => {
		 //let status=order.status;
		 let orderCode=res.data;
            if (orderCode && orderCode!=="") {
              
            let ordernum = orderCode.split(":"); 
           // payload.code=ordernum[1];
            if (ordernum[0] && ordernum[0]==="success")
            {
               //change id and PingUrl	
               order.id=ordernum[1];
               order.pingUrl="";	
			    this.saveOrders();
               return this.checkOrder(order);				
            }             
            else if (ordernum[0] && ordernum[1] && ordernum[0]==="error" && (ordernum[1]==="5014" || ordernum[1]==="5011" || ordernum[1]==="5012"))
            {
			  order.status=100;
			  order.pingUrl="";	
			  this.saveOrders();
             // payload.msg=cartStore.menuStore.translation.payment_cancel;
              //obj._openEmailMessage(payload);      
            } 
		  }
		  return order; 
		})
        .catch(err => {
		  return order; 
	   });
                    		 
	 }	
	else
	{
     return API.getOrder(order.id)
      .then(res => {
		  let oldStatus="";
		  let spotStatus='';
          if(order.status<2 && (res.success===true || res.merchant_status===2))
		  {	 
    	  _.each(order.cartEntries, entry => {
			 const { item, addons } = entry;							
               _.each(addons, (addonList, addonListIndex) => {								 
									let keys = Object.keys(addonList);
									_.each(keys, (key, index) => {
										//console.log(key,addonList[key]);
										_.each(addonList[key], (addon, addonIndex) => {
										     
											 if(addon.quantity>0)
											 {
												try
												{											   
													window.gtag('event',addon.name, { 'event_category': 'Confirm', 'event_label': this.controller, 'value': addon.price*addon.quantity*100});
												}
												catch (err) {
												console.log(err);
												}
											 }										 
											 
										});
									});								 
							 });
							try
							{
								window.gtag('event',item.name, { 'event_category': 'Confirm', 'event_label': this.controller, 'value': item.price*addons.length*100});
							}
							catch (err) {
								console.log(err);
							}
					}); 
		  }
          	  
        //order.status = res.success;
        //order.status = res.merchant_status;
		let oStatu=null;
		let spotOrderid=order.spotOrderId;
		if(res.success===true)
		{
			oStatu= 2;
			spotStatus='COMPLETED';
			order.spotOrderId="";
		}
		if(res.success===false && res.merchant_status==="")
		{
			oStatu= 300;
			spotStatus='CANCELLED';
			order.spotOrderId="";
		}
		if(res.merchant_status)
		{
			oStatu=res.merchant_status;			
		}
		if(oStatu===100)
		{
			spotStatus='CANCELLED';
		}
		if(res.success===undefined)
		{
			spotStatus='CANCELLED';
			oStatu=90;
		}
		
		if(res.success===true && (res.merchant_status==="" || res.merchant_status===null))
		{
			oStatu= 200;
			spotStatus='COMPLETED';
			order.spotOrderId="";
		}
		order.status =oStatu;
		
		let msg=this.translation.order_check_again;
		if(res.merchant_remark)
		{
			msg=res.merchant_remark;
		}
		if(res.msg)
		{
			msg=res.msg;
		}
        order.info = encodeURIComponent(msg);
        order.orderNo=res.orderNo;
		order.priceReceipt=res.priceReceipt;
        this.saveOrders();
		if(spotOrderid !== undefined && oldStatus!==order.status && spotOrderid!=="" && spotStatus!=="")
		{
			API.updateSpotOrder(spotOrderid,spotStatus,order.id);
		}
        return {
          orderNo: res.orderNo,
          id: order.id,
          status: order.status,
          info: order.info,
		  spotOrderId:order.spotOrderId,
		  priceReceipt:order.priceReceipt
        };
      })
      .catch(err => {
		console.log(err);
		if(err.status !== 404) {
			return {
				id: order.id,
				orderNo: order.orderNo,
				status: order.status,
				info: order.info
			};
		}
		 if (navigator.onLine || err.status === 404)
		 {
			 order.status = 90;
		 }       
        //order.info = this.translation.order_expired;
        this.saveOrders();
        return {
          id: order.id,
          orderNo: order.orderNo,
          status: order.status,
          info: order.info,
		  spotOrderId:order.spotOrderId,
		  priceReceipt:order.priceReceipt
        };
      });
	}
  }

  @action saveOrders() {
    localStorage.setItem(ORDER_SAVE_KEY, JSON.stringify(this.lists));
  }
  @action updateOrder(payload,status)
  {
	  let allOrders=this.lists;
	  let newOrderlist=[];
	  
	  _.each(allOrders, ordr => {
		  if(ordr.id===payload.refno)
		  {
			  ordr.status=status;
		  }
		  newOrderlist.push(ordr);
	  });
	  this.lists=newOrderlist;
	  this.saveOrders();
  }
  @action updateOrderNewStatus(order)
  {
	  let allOrders=this.lists;
	  let newOrderlist=[];
	  
	  _.each(allOrders, ordr => {
		  if(ordr.id===order.id)
		  {
			  ordr.isNew=false;
		  }
		  newOrderlist.push(ordr);
	  });
	  this.lists=newOrderlist;
	  this.saveOrders();
  }
  @action clearOrders() {
    localStorage.removeItem(ORDER_SAVE_KEY);
    this.lists = [];
	localStorage.removeItem("preQuestions");
	localStorage.removeItem("postQuestions");	
	localStorage.removeItem("is_opened_post_question");
	localStorage.removeItem("is_opened_pre_question");
  }
}

export default OrderStore;
