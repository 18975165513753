import React from "react";
import { FaEdit } from "react-icons/fa";
//import { Modal } from "antd-mobile";
import { observer, inject } from "mobx-react";

@inject("cartStore")
@observer
class MenuDate extends React.Component {

    render = () => {
        if(this.props.cartStore.menuStore.isPreOrder)
        {       
        return (
            <div className="dateSection"><h3>Order Date / Time: {this.props.mdateTime} <FaEdit size={15}
                onClick={
                    () => {
                        if (this.props.cartStore.menuStore.preQuestionFileds.length > 0 &&  this.props.cartStore.menuStore.isPreOrder ) {
                           // this.props.cartStore.menuStore.isHomeModalVisible = true;
                            localStorage.removeItem("is_opened_pre_question");
                            this.props.cartStore.menuStore.enablePreQuestionPopup();
                            //console.log(this.props.cartStore.menuStore.preQuestionFileds.length);
                        }
                        else {
                            //console.log(5);
                        }
                    }
                }
            /></h3></div>

        );
        }
        else
        {
            return '';
        }
    };
}

export default MenuDate;
