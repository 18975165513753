import React, { Fragment } from "react";
import { Button } from "antd-mobile";
import PromptPopup from "./Popup";
import CircularProgressbar from "react-circular-progressbar";
import API from "../libs/api";
import configParam from "../libs/params";

const timeInterval = [2, 3, 4, 5, 10, 15, 20, 25, 30];
const COUNTDOWN_LIMIT = 3;
let countdownTimer;
let cancelCheckout;
let pollingTimer;

class CheckBill extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      excludedItesm: [],
      htmlMsg: false
    };
  }
  _checkPastOrder()
  {
    let orderList=this.props.cartStore.orderStore.lists;
   let codes=[];
   let cDt = new Date(); // some mock date
   let cTmestamp = cDt.getTime();
    for(let ol=orderList.length-1;ol>=0;ol--)
    {
      let oder=orderList[ol];
      let dateorderDt = new Date(oder.timestamp); // some mock date
      let oTimestamp = dateorderDt.getTime(); 
      let diffStamp=Math.ceil((cTmestamp-oTimestamp)/(3600*1000));
     if(diffStamp<=2)
        codes.push(oder.id);       
    }
    return codes.join(', ');
  }
  _prepareBillCountdown = () => {
    const { cartStore } = this.props;
    if (!navigator.onLine) {
      this.setState({
        promptPopup: true,
        promptTitle: cartStore.menuStore.translation.no_internet,
        promptContent: cartStore.menuStore.translation.error_network,
        promptLoading: false,
        closePopup: this._defaultClose()
      });

      return;
    }
    let payload =this._checkPastOrder();
    if(payload==="")
    {
      this.setState({
        promptPopup: true,
        promptTitle: cartStore.menuStore.translation.request_bill_error,
        promptContent: cartStore.menuStore.translation.request_bill_error_msg,
        promptLoading: false,
        closePopup: this._defaultClose()
      });
      return;
    }    

    this.setState(
      {
        timeCountdown: COUNTDOWN_LIMIT
      },
      () => {
        this.setState({
          promptPopup: true,
          promptTitle: cartStore.menuStore.translation.checkoing_current_order,
          promptContent: cartStore.menuStore.translation.waiting_respond_outlet,
          promptLoading: false,
          closePopup: this._defaultClose(
            this._renderCircleProgress(this.state.timeCountdown)
          )
        });
      }
    );
    countdownTimer = setInterval(() => {
      this.setState(
        {
          timeCountdown: this.state.timeCountdown - 1
        },
        () => {
          this.setState({
            closePopup: this._defaultClose(
              this._renderCircleProgress(this.state.timeCountdown)
            )
          });
        }
      );
    }, 1000);

   
    
    cancelCheckout = setTimeout(() => {
      clearInterval(countdownTimer);
    
      this.setState({
        promptLoading: true,
        promptTitle: cartStore.menuStore.translation.waiting_respond_outlet,
        promptContent: "",
        closePopup: this._defaultClose()
      });

      // Handle failed checkout
      countdownTimer = setTimeout(() => {
        this.setState({
          promptTitle: cartStore.menuStore.translation.error,
          promptContent: cartStore.menuStore.translation.error_network,
          promptLoading: false,
          closePopup: this._defaultClose()
        });
      }, 20000);

      this._callBillApi(payload)
        .then(res => {
          clearTimeout(cancelCheckout);
          clearTimeout(countdownTimer);
          if (res.pushMsg) {
            let pumsg = JSON.parse(res.pushMsg);
            if (parseInt(pumsg.status) === 1 || pumsg.status === "1") {
               let url = res.checkUrl + "?accessToken="+cartStore.menuStore.tokenAccess;
              this.getPollStatus(0, url);
            }
            else {
              this._setDefaultMessage({ title: cartStore.menuStore.translation.error, content: cartStore.menuStore.translation.error_network });
            }
          }
          else {
            this._setDefaultMessage({ title: cartStore.menuStore.translation.error, content: cartStore.menuStore.translation.error_network });
          }
        })
        .catch(err => {
          clearTimeout(cancelCheckout);
          clearTimeout(countdownTimer);
          this.setState({
            promptTitle: err.title,
            promptContent: err.content,
            promptLoading: false,
            closePopup: this._defaultClose()
          });
        });
    }, COUNTDOWN_LIMIT * 1000);
  };
  callGetStatusApi(url) {
    let { cartStore } = this.props;
    return new Promise((resolve, reject) => {
      API.checkOrderStatus(url)
        .then(res => {

          if (res.toString().indexOf("PHP Error") !== -1 || res.length === 0) {
            reject({
              title: cartStore.menuStore.transation.error,
              content: cartStore.menuStore.transation.error_network
            });
          } else {
            resolve(res);
          }
        })
        .catch(err => {
          reject({
            title: cartStore.menuStore.translation.error,
            content: cartStore.menuStore.translation.error_network
          });
        });
    });
  }
  getPollStatus(ind, url) {
     let { cartStore } = this.props;
    let timeArr = timeInterval;
    let timePoll = parseInt(timeArr[ind]);
    ind++;
    this.callGetStatusApi(url)
      .then(res => {
        if (res.status !== "1" && res.status !== "-1" && timePoll) {            
          clearTimeout(pollingTimer);
          pollingTimer = setTimeout(() => {
            return this.getPollStatus(ind, url);
          }, timePoll * 1000);         
        }
        else if (res.success || res.success === false) {
          this.setState({
            promptTitle: "",
            promptContent: res.lastMessage,
            promptLoading: false,
            htmlMsg: true,
            closePopup: this._defaultClose()
          });
        }
        else {
          let message = cartStore.menuStore.translation.order_check_again;
          if(res.status === 1) {
            message=cartStore.menuStore.translation.order_check_confirm;
          }
          this.setState({
            promptLoading: false,
            promptTitle: cartStore.menuStore.translation.checkoing_current_order,
            promptContent: message,
            closePopup: this._defaultClose()
          });
        }

        return res;
      });
  }
  _setDefaultMessage = (res) => {
    this.setState({
      promptTitle: res.title,
      promptContent: res.content,
      promptLoading: false,
      closePopup: this._defaultClose()
    });
  }
  _defaultClose = closeText => {
    const { cartStore } = this.props;
    return [
      {
        text: closeText
          ? closeText
          : cartStore.menuStore.translation.close,
        onPress: () => {
          clearInterval(countdownTimer);
          clearTimeout(cancelCheckout);
          this.setState({           
            promptPopup: false,
            htmlMsg: false,
            promptContent: ""
          });
        }
      }
    ];
  };
  _renderCircleProgress = (countdown, total_limit, closeText) => {
    let total_time = total_limit ? total_limit : COUNTDOWN_LIMIT
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <div style={{ position: "absolute", width: "100%" }}>
          {closeText ? closeText : this.props.cartStore.menuStore.translation.cancel}
        </div>
        <div
          style={{
            width: "35px",
            float: "right",
            paddingRight: 12,
            paddingTop: 4,
            position: "relative"
          }}
        >
          <CircularProgressbar
            percentage={
              (Math.abs(countdown - total_time) / total_time) * 100
            }
            strokeWidth={50}
            textForPercentage={null}
            styles={{
              width: 20,
              path: { strokeLinecap: "butt" },
              text: { fill: "#000" }
            }}
          />
        </div>
      </div>
    );
  };
  _callBillApi(payload) {
    let { cartStore } = this.props;

    if (!navigator.onLine) {
      return Promise.reject({
        title: cartStore.menuStore.translation.no_internet,
        content: cartStore.menuStore.transation.error_network
      });
    }
        return new Promise((resolve, reject) => {
      API.requestBillNewApi(payload)
        .then(res => {
          if (res.toString().indexOf("PHP Error") !== -1) {
            reject({
              title: cartStore.menuStore.transation.error,
              content: cartStore.menuStore.transation.error_network
            });
          } else {
            resolve(res);
          }
        })
        .catch(err => {
          console.log(err);
          reject({
            title: cartStore.menuStore.translation.error,
            content: cartStore.menuStore.translation.error_network
          });
        });
    });
  };
  
  _renderPromptPopup = () => {
    return (
      <PromptPopup
        visibility={this.state.promptPopup}
        title={this.state.promptTitle}
        closePopup={this.state.closePopup}
        content={this.state.promptContent}
        isLoading={this.state.promptLoading}
        htmlMsg={this.state.htmlMsg}
		 cartStore={this.props.cartStore}
      />
    );
  };

  render() {
    let payload =this._checkPastOrder();
    if(payload==="" || this.props.cartStore.menuStore.bill_type.toString()!=='1')
    {
      return ('');
    }
    return (
      <Fragment>
        <div className="withoutLog clearCartBtn" >              
			       <Button 
               className="bypassbtn"             
              onClick={() => {                  
                 this._prepareBillCountdown();              					
              }}             
              >
               <img src={configParam.fzCheckerImage} style={{verticalAlign:"middle"}}  width="30px" alt="Foodzaps" />
               <span style={{ marginLeft:"5px" }}>{this.props.cartStore.menuStore.translation.check_bill_btn}</span>			  
              </Button>
              </div>      
        {this._renderPromptPopup()}
      </Fragment>
    );
  }
}

export default CheckBill;
