import React  from "react";
import { observer, inject } from "mobx-react";
import { Menu} from "antd";
import "antd/dist/antd.css";
import AddressInfo from "./AddressInfo";
//const alert = Modal.alert;
//import { FiEdit } from "react-icons/fi";

//const { SubMenu } = Menu;
@inject("cartStore")
@observer
class CocoMenu extends React.Component {

    componentDidUpdate(prevProps) {
        // Check if menu selection has changed
        const prevKey = prevProps && prevProps.cartStore && prevProps.cartStore.menuStore ? prevProps.cartStore.menuStore.horizontalMenuSelectedIndex : null;
        const currentKey = this.props.cartStore && this.props.cartStore.menuStore ? this.props.cartStore.menuStore.horizontalMenuSelectedIndex : null;
        const prevPromoKey = prevProps && prevProps.cartStore && prevProps.cartStore.menuStore ? prevProps.cartStore.menuStore.PromoMenuKey : null;
        const currentPromoKey = this.props.cartStore && this.props.cartStore.menuStore ? this.props.cartStore.menuStore.PromoMenuKey : null;
        
        // If selection has changed (possibly from drawer menu)
        if (prevKey !== currentKey || prevPromoKey !== currentPromoKey) {
            setTimeout(() => {
                const selectedItem = document.querySelector(".ant-menu-item-selected");
                if (selectedItem) {
                    selectedItem.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
                }
            }, 100);
        }
    }

    renderCocoHorizontalMenu=(data)=>
    {    
        let promolength=this.props.cartStore.menuStore.promotionsLength;
		//let promos=this.props.cartStore.menuStore.promotions;   
        return (
		
         <Menu
            defaultSelectedKeys={['0']}
            mode="horizontal "
            id={promolength>0?"catgeory-horizontal":"cocomunu-horizontal"}
            selectedKeys={this.props.cartStore.menuStore.horizontalMenuSelectedIndex}
			className="catgeory-horizontal"
			
          >   
            {data.map((item, index) => (
            <Menu.Item            
            key={index}
			id={"submenucoco"+index}
            onClick={(obj) => {		
                 	
                let key=parseInt(obj.key);
				//console.log(5555,key);
                // //this.props.cartStore.menuStore.horizontalMenuSelectedKey = item;
               // // this.setState({selectedKey:[key.toString()]});
			    
				document.getElementById(item).scrollIntoView();
				//document.getElementById(item).scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
				 
				this.props.cartStore.menuStore.horizontalMenuSelectedIndex=[key.toString()];
                
                // Center the selected menu item
                setTimeout(() => {
                    const selectedItem = document.querySelector(".ant-menu-item-selected");
                    if (selectedItem) {
                        selectedItem.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
                    }
                }, 100);
            }}
            >{item}</Menu.Item>
            ))
            }
          </Menu>)
    }
    renderCocoHorizontalPromos=()=>
    {        

		let promolength=this.props.cartStore.menuStore.promotionsLength;
		let data=this.props.cartStore.menuStore.promotions;
        if(promolength===0)
		{
			return ('');
		}
        return (
		
         <Menu
            //defaultOpenKeys={['0']}
            defaultSelectedKeys={['0']}
            mode="horizontal "
            id="cocomunu-horizontal"
            selectedKeys={[this.props.cartStore.menuStore.PromoMenuKey.toString()]}
          >   
            {data.map((item, index) => (
            <Menu.Item            
            key={index}
            onClick={(obj) => {
				 
				 this.props.cartStore.menuStore.horizontalMenuSelectedKey= item;
					         this.props.cartStore.menuStore.activePromo=index;
					         this.props.cartStore.menuStore.PromoMenuKey=index;
                             this.props.cartStore.menuStore.resetMeuList();
                             this.props.cartStore.menuStore.searchResult =this.props.cartStore.menuStore.filterMenuList;
							 
							// console.log(this.props.cartStore.menuStore.PromoMenuKey,'88888');
					          // let ele = document.querySelectorAll(".am-accordion-header");
					          // if(ele.length>0)
							  // {
                               // let elem=ele[index];								  
						        // elem.style.cursor = "pointer";
								// elem.click();								
							  // }	
						
					var catsheader = document.querySelectorAll(".menuHeader");
                   catsheader[0].scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });

                   // Center the selected menu item
                   setTimeout(() => {
                       const selectedItem = document.querySelector(".ant-menu-item-selected");
                       if (selectedItem) {
                           selectedItem.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
                       }
                   }, 100);
				   
				   //this.props.cartStore.menuStore.horizontalMenuSelectedIndex=["0"];
				  
            }}
            >{item}</Menu.Item>
            ))
            }
          </Menu>)
    }
    render() { 
	   let data=this.props.cartStore.menuStore.horizontalCategoryList;
        return (
              
	         <div className="topsection">
			   <AddressInfo page="menu"  prelength={this.props.cartStore.menuStore.preQuestionFileds.length} />
               <div className="cocoMenu cocoHorizontalMenu">
			    {this.renderCocoHorizontalPromos()}
                {this.renderCocoHorizontalMenu(data)}
                </div>
           </div>   
        )
    }
}

export default CocoMenu;