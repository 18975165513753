const params= {
  fzdomain: "https://www.foodzaps.com",
  fzlogo:"https://www.foodzaps.com/public/img/ppp_name_logo4.png",
  fzLoadingImage:"https://www.foodzaps.com/public/img/ppp_loading.gif",
  fzCheckerImage:"https://www.foodzaps.com/public/img/checkorder.png",
  fzSharingApi:"https://share.foodzaps.com/index.php?",
  scanUrl:"https://scan.foodzaps.com/",
  verificationServer:"https://gcstagpayverify.foodzaps.com/",
  t05TestPaymentUrl:"https://gcstagpayverify.foodzaps.com/t05-redirect/upwork",
  fzTestPaymentUrl:"https://gpaymenu.foodzaps.com/extra/test-payment.php",
  eghlPaymentUrl:"https://test2pay.ghl.com/IPGSG/Payment.aspx",
  eghlMerchantTermUrl:"https://my.foodzaps.com",
  ipay88PaymentUrl:"https://payment.ipay88.com.my/ePayment/entry.asp",
  pineAppleRoundImage:"https://www.foodzaps.com/public/img/pineapple_round.png",
  pineAppleLogo:"https://www.foodzaps.com/public/img/pineapple_colour.png",
  pineAppleMemberApi:'https://admin2.foodzaps.com/api/member/info',
  googleMapUrl:"https://maps.googleapis.com/maps/api/js?key=",
  spotMicroJs:"https://microapps.google.com/apis/v1alpha/microapps.js",
  unisoln_controller:"81dd3214-2853-4d41-878e-1220e6daa494",  
  recomendCacheUrl:"https://fzmenu.foodzaps.com/recommendation/",
  postalApi:"https://www.sglocate.com/api/json/searchwithpostcode.aspx",
  postalApiKey:"93F363DF50DB4CDEA27E78F67160DE1C4D7A967A44F04A7DA06C12517CB7D90E",
  postalApiSecret:"B251C61C22B3466A98F7E5F45DF243078A77B619DD12485FA4771E524573008A",
  dbaRedirectPaymentUrl:"https://gpaymenu.foodzaps.com/extra/dba-payment.php",
  adyenRedirectPaymentUrl:"https://gpaymenu.foodzaps.com/extra/adyen-payment.php",
  eberRedirectUrl:"https://gpaymenu.foodzaps.com/extra/eber-redirect.php",
  eberRewardsUrl:"https://oauth.connectors.eber.io/api/v3/public/integration/redeemable_item/show?redeem_code=",
  eberProfileUrl:"https://oauth.connectors.eber.io/api/v3/public/integration/user/show?list_redeemable=1",
  bwProfileUrl:"https://dec.tastecn.com/partner/integration/user/show?list_redeemable=1",  
  //bwProfileUrl:"https://decsit2.tastecn.com/partner/integration/user/show?list_redeemable=1",  
  rewardImage:"https://dehggv6ly7hcl.cloudfront.net/resized/1667360715_5pGRv3vp8ZB417M1P4mtM8O8aNNzzNYcxbfPYwqD_600.jpg",
  apiAddOrder: "https://gcorder.foodzaps.com/online",
  
  advocadoRewardsUrl: "https://gcsopenapi.foodzaps.com/advocado/campaigns/list",
  advocadoTokenUrl: 'https://gcsopenapi.foodzaps.com/advocado/token',
  advocadoBaseUrl: 'https://gcsopenapi.foodzaps.com/advocado'
};


export default params;
