import React from "react";
import { inject } from "mobx-react";
import request from "../../libs/request";
import configParam from "../../libs/params";

import {EmailIcon,FacebookIcon,WhatsappIcon, WeiboIcon} from "react-share";
import {EmailShareButton,FacebookShareButton, WhatsappShareButton, WeiboShareButton} from "react-share";

@inject("cartStore")
class Sharing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url:"",
            restName:"",
			loading:true
        };

    /*    request.get(url+"b64="+formatData).then(res => {
            url=url+"id="+res.id
    });
    console.log(url,'ahgjfag');*/
   

    }
    componentDidMount() {
        //console.log('componentDidMount');
        const { itemModal } = this.props.cartStore.menuStore;
        let restName = this.props.cartStore.menuStore.store.name;
        let controllerName = this.props.cartStore.menuStore.controller;
        let redirectUrl = window.location.protocol + "//" + window.location.host;

        let formatData="";
        if(itemModal.item.name)
        {
        formatData=itemModal.item.name.replace(';',':');
        }
        formatData+=";";
        if(!restName  || restName==="")
        {
            restName="powered by PunchPoint";
          //formatData+=restName.replace(';',':');
        }
        restName=restName.replace(/<[^>]+>/g, '');
        formatData+=restName.replace(';',':');
        formatData+=";";
        formatData=btoa(encodeURIComponent(formatData+controllerName+';'+itemModal.item.thumbnail+';'+redirectUrl));
        let url= configParam.fzSharingApi;
        request.get(url+"b64="+formatData).then(res => {
			if(res.id && res.id!=="")
			{
			let url1=url+"id="+res.id;
            this.setState({ url: url1, restName:restName,loading:false});	
			}
			else{				
                 this.setState({ url: '', restName:restName,loading:false});
			 }
           
         }).catch(err => {
            this.setState({ url: '', restName:restName,loading:false});
        });
     }
    
     /*componentWillUnmount() { 
        console.log('componentWillUnmount');
     }
     componentDidUpdate() { 
        console.log('componentDidUpdate');
     } */     

    render() {
        let allShareBtns=this.props.cartStore.menuStore.shareBtns.split(',');
        const { itemModal } = this.props.cartStore.menuStore;
        	//{title};{description};{controller};{image url};{redirect url}
					  /*let restName = this.props.cartStore.menuStore.store.name;
                    
                      let controllerName = this.props.cartStore.menuStore.controller;
                      let redirectUrl = window.location.protocol + "//" + window.location.host;

                      let formatData="";
                      if(itemModal.item.name)
                      {
                      formatData=itemModal.item.name.replace(';',':');
                      }
                      formatData+=";";
                      if(!restName  || restName==="")
                      {
                          restName="powered by FoodZaps";
                        //formatData+=restName.replace(';',':');
                      }
                      restName=restName.replace(/<[^>]+>/g, '');
                      formatData+=restName.replace(';',':');
                      formatData+=";";
                      formatData=btoa(encodeURIComponent(formatData+controllerName+';'+itemModal.item.thumbnail+';'+redirectUrl));
                      let url= "http://foodzextra.com/sharing/index.php?";*/
                      //let url= "https://share.foodzaps.com/?";
                      
                   
        if(allShareBtns.length>0)
        {
         if(this.state.loading)
		 {
			return (             
					<div className="shareBtnsloading">
					<div style={{"padding":"7px"}} className="loading"></div>
					</div>
					);
		 }	
         if(this.state.url!=="")
		 {	
            if(allShareBtns.indexOf("EmailShareButton")>-1 || allShareBtns.indexOf("FacebookShareButton")>-1 || allShareBtns.indexOf("WhatsappShareButton")>-1 || allShareBtns.indexOf("WeiboShareButton")>-1)
			{							
			return (             
					<div className="shareBtns">
						{allShareBtns.indexOf("EmailShareButton")>-1? (  
						<EmailShareButton subject={'|'+this.state.url} body={itemModal.item.name}  separator="|">
						 <EmailIcon size={32} round={true} />
						</EmailShareButton>):('')}
						{allShareBtns.indexOf("FacebookShareButton")>-1? (  
						<FacebookShareButton quote={itemModal.item.name} hashtag={this.state.restName} url={this.state.url} >
							 <FacebookIcon size={32} round={true} />
						</FacebookShareButton>):('')}	
						{allShareBtns.indexOf("WhatsappShareButton")>-1? (  
						<WhatsappShareButton title={itemModal.item.name}  url={this.state.url}  separator="|" >
							 <WhatsappIcon size={32} round={true} />
						</WhatsappShareButton>):('')}
						{allShareBtns.indexOf("WeiboShareButton")>-1 ?(  
						<WeiboShareButton title={itemModal.item.name}  url={this.state.url} image={itemModal.item.thumbnail} >
							 <WeiboIcon size={32} round={true} />
						</WeiboShareButton>):('')}
					
						</div>);
			 }
		   }
        }      
        return ('');      
        
    }
}

export default Sharing;