import Request from "./request";

let controllerName = (() => {
    return window.location.pathname.split("/")[1];
})();
//localStorage.clear();
let controllerID = null;

const baseURL = `https://gcorder.foodzaps.com`;
const verifyServer = `https://gcstagpayverify.foodzaps.com/`;
const tokenAcces = "foodzaps";
//const verifyServer = `http://localhost:8081`;

let cachedTag = localStorage.getItem(controllerName);
let tag = cachedTag ? JSON.parse(cachedTag).tag : null;

const API = {
    // this function will check everytime an api is called to set the controllerID
    // because this is an object initiated at different places of the application
    // not really good but too late to change.
    getDomainUrl: () => {
        //console.log(controllerID,'controllerID');
        // API.checkControllerId();
        let apiServer = localStorage.getItem(`${controllerID}_apiserver`);
        // console.log(`${controllerID}_apiserver`,apiServer);
        if (apiServer !== null && apiServer !== "" && apiServer !== "undefined" && apiServer !== undefined) {
            if (apiServer.charAt(apiServer.length - 1) === '/') {
                apiServer = apiServer.slice(0, -1);
            }
            //request = `${apiServer}/add/${controllerID}?returnHTML=0&tag=${tag}`;
        } else {
            apiServer = baseURL + "/online";
        }

        return apiServer;
    },
    checkControllerId: () => {
        if (controllerID === null) {
            controllerID = localStorage.getItem(`${controllerName}_id`);

            cachedTag = localStorage.getItem(controllerName);
            tag = cachedTag ? JSON.parse(cachedTag).tag : null;
        }
    },
    getMenu: isCached => {
        const endpoint = `https://gcmenu.foodzaps.com/menu/2api/${controllerName}/true/${isCached}/2`;
        //const endpoint = `https://www.foodzaps.com/v10_mobile_staging/menu/2api/${controllerName}/true/${isCached}/2`;
        //const endpoint = `https://onlineordering-env-29zy38zqgq.foodzaps.com/menu/2api/${controllerName}/true/${isCached}/2`;
        //const endpoint = `http://localhost:8081/menu/2api/${controllerName}/true/${isCached}/2`;
        //const endpoint = `https://inruo.serveo.net/menu`;
        // return Request.get(endpoint);
        return Request.getGZipApi(endpoint);
    },
    prepareOrderRequest: (payload) => {
        API.checkControllerId();
        let serverUrl = API.getDomainUrl();
        let request = serverUrl + `/createOrder/${controllerID}?accessToken=` + tokenAcces + `&type=web&returnHTML=0&tag=${tag}`;
        request += `${payload}`;
        return Request.get(request);
    },
    calculateTaxs: orderId => {
        API.checkControllerId();
        // check if order is successful
        // if no success in json response = pending order
        //const request = `${baseURL}/v13_mobile/online/getOrderStatus/${controllerID}/${orderId}?accessToken=foodzaps`;
        let serverUrl = API.getDomainUrl();
        const request = serverUrl + `/getOrderStatus/${controllerID}/${orderId}?accessToken=` + tokenAcces;
        return Request.get(request);
    },
    createOrder: (payload, verificationCode) => {
        API.checkControllerId();
        let serverUrl = API.getDomainUrl();
        let request = serverUrl + `/add/${controllerID}?returnHTML=0&type=web&tag=${tag}`;

        if (payload.indexOf("payment=") !== -1 && payload.indexOf('"method":"cash"') === -1) {
            let serverUrl1=API.getPaymentServerUrl();
            request = `${serverUrl1}verify/add/${controllerID}?returnHTML=0&type=web&tag=${tag}&serverUrl=${encodeURIComponent(serverUrl)}`;
        }
        if (verificationCode) request += `&verify=${verificationCode}`;

        request += `${payload}`;
        return Request.get(request);
    },
    checkOrder: (payload, verificationCode) => {
        API.checkControllerId();
        let serverUrl = API.getDomainUrl();
        let request = serverUrl + `/verifyOrder/${controllerID}?accessToken=` + tokenAcces + `&returnHTML=0&tag=${tag}`;
        if (verificationCode) request += `&verify=${verificationCode}`;
        request += `${payload}`;
        return Request.get(request);
    },
    checkOrderStatus: url => {
        return Request.get(url);
    },
    changeTableCallApi: (payload) => {
        API.checkControllerId();
        let serverUrl = API.getDomainUrl();
        let request = serverUrl + `/transferOrder/${controllerID}?accessToken=` + tokenAcces + `&returnHTML=0&tag=${tag}`;
        request += `${payload}`;
        return Request.get(request);
    },
    requestBillNewApi: (msg) => {
        API.checkControllerId();
        let serverUrl = API.getDomainUrl();
        const requestUrl = serverUrl + `/bill/${controllerID}`;
        return Request.post(requestUrl, `accessToken=` + tokenAcces + `&returnHTML=0&tag=${tag}&order=${encodeURIComponent( msg )}`);
    },
    addOrderByPost: (suburl,payload) => {
        API.checkControllerId();
        let serverUrl=API.getPaymentServerUrl();
        const requestUrl = serverUrl +suburl;
       // const requestUrl = `http://localhost:8081/`+suburl;
        //console.log(requestUrl);
         return Request.post(requestUrl, `accessToken=` + tokenAcces + `&returnHTML=0&tag=${tag}&`+payload);
    },
    getOrder: orderId => {
        API.checkControllerId();
        // check if order is successful
        // if no success in json response = pending order
        let serverUrl = API.getDomainUrl();
        const request = serverUrl + `/get/${controllerID}/${orderId}?act=test`;

        return Request.get(request);
    },
    callForWaiter: msg => {
        API.checkControllerId();
        let serverUrl = API.getDomainUrl();
        const request = serverUrl + `/call/${controllerID}?action=waiter&tag=${tag}&msg=${encodeURIComponent(
      msg
    )}`;

        return Request.get(request);
    },
    callForBill: msg => {
        API.checkControllerId();
        let serverUrl = API.getDomainUrl();
        const request = serverUrl + `/call/${controllerID}?action=cashier&tag=${tag}&msg=${encodeURIComponent(
      msg
    )}`;

        return Request.get(request);
    },
    sendEmail: msg => {
            const request = `${verifyServer}send-email?msg=${encodeURIComponent(msg)}`;
            return Request.get(request);
        },
    getPaymentServerUrl:()=>
    {
        let verifyServerRest = localStorage.getItem(`${controllerName}_verifyserver`);
        let serverUrl1 = verifyServer;
        if (verifyServerRest) {
            serverUrl1 = verifyServerRest;
        }
        return serverUrl1;
    } ,
    checkShippingCharge: (url, params) => {       
        return Request.postJson(url, params);
    }, 
	callPostJson: (suburl, params) => {  
        API.checkControllerId();
        let serverUrl=API.getPaymentServerUrl();
        const requestUrl = serverUrl +suburl;    
        return Request.postJson(requestUrl, params);
    },
	updateSpotOrder:(customOrderId,status, label)=>{
		API.checkControllerId();
		let payload = { controllerId: controllerID,customOrderId: customOrderId,status:status,message:label + " - Pay at counter after meal" };
		if(localStorage.getItem("spotMethod")==='spot')
		{
		  let resp=API.callPostJson('update-spot-order', payload);
		  console.log(resp);	
		}		
    },
    createSpotMessage:(message)=>{
		API.checkControllerId();
		let payload = {
            controllerId: controllerID,
            message: {
                ...message
            }
        };
		if(localStorage.getItem("spotMethod")==='spot')
		{
            let resp=API.callPostJson('create-spot-message', payload);
            console.log(resp);	
		}		
	},
	getPingStaus:(pingurl)=>{		
		return Request.get(pingurl+"?accessToken=foodzaps&type=QR");		
	},
    getPingYeahpayStatus:(controller, refId)=>{		
		return Request.postJson(verifyServer + `yeahpay/order?controller=${controller}&sandbox=false`, {
            "orderId":refId
        });		
	},
    callbackPingYeahpayStatus:(paymentPayload)=>{		
		return Request.postJson(verifyServer + "yeahpay/callback", paymentPayload);		
	},
	requestAddressBasedPostal: (url, params) => {
        return Request.post(url,params);
    },
        /*,
        getIP:() => {    
         // const request = `${baseURL}/get-ip`;
          const request = 'https://api.ipify.org/?format=json';
          return Request.get(request);
        }*/
};

export default API;