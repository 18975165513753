import React  from "react";
import { observer, inject } from "mobx-react";
import { Menu} from "antd";
import "antd/dist/antd.css";
import AddressInfo from "./AddressInfo";
//const alert = Modal.alert;
//import { FiEdit } from "react-icons/fi";

//const { SubMenu } = Menu;
@inject("cartStore")
@observer
class CocoMenuSide extends React.Component {
    renderCocoHorizontalMenu=(data)=>
    {    
        let promolength=this.props.cartStore.menuStore.promotionsLength;
        return (
            <React.Fragment
                defaultSelectedKeys={['0']}
                mode="horizontal "
                id={promolength>0?"catgeory-horizontal":"cocomunu-horizontal"}
                selectedKeys={this.props.cartStore.menuStore.horizontalMenuSelectedIndex}
                className="catgeory-horizontal"
            >   
                {data.map((item, index) => {
                    let active = 'drawermenu';
                    console.log('activePromo',this.props.cartStore.menuStore.activePromo);
                    console.log('PromoMenuKey',this.props.cartStore.menuStore.PromoMenuKey);
                    if(this.props.cartStore.menuStore.activePromo===index) {
                        active = 'drawermenu active';
                    }
                    return (
                        <div   
                            className={active}
                            style={{ cursor: 'pointer' }}   
                            key={index}
                            id={"sidemenucoco"+index}
                            onClick={(obj) => {		
                                let key=parseInt(obj.key);
                                console.log('key', key);
                                document.getElementById(item).scrollIntoView();
                                this.props.cartStore.menuStore.horizontalMenuSelectedIndex=[key.toString()];
                                this.props.cartStore.menuStore.activePromo=index;
                                this.props.cartStore.menuStore.PromoMenuKey=index;
                                
                                // Center the selected menu item
                                setTimeout(() => {
                                    const selectedItem = document.querySelector(".drawermenu.active");
                                    if (selectedItem) {
                                        selectedItem.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
                                    }
                                }, 100);
                                
                                this.props.onClose();
                                
                                // Center the selected item in the main menu after drawer closes
                                setTimeout(() => {
                                    const selectedItem = document.querySelector(".ant-menu-item-selected");
                                    if (selectedItem) {
                                        selectedItem.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
                                    }
                                }, 300);
                            }}
                        >
                            {item}
                        </div>
                    )
                })}
            </React.Fragment>
        )
    }
    renderCocoHorizontalPromos=()=>
    {   
		let promolength=this.props.cartStore.menuStore.promotionsLength;
		let data=this.props.cartStore.menuStore.promotions;
        if(promolength===0)
		{
			return ('');
		}
        return (
            <React.Fragment>   
                {data.map((item, index) => {
                    let active = 'drawermenu';
                    if(this.props.cartStore.menuStore.PromoMenuKey==index) {
                        active = 'drawermenu active';
                    }
                    return (
                        <div            
                            className={active}
                            style={{ cursor: 'pointer' }}
                            key={index}
                            onClick={(obj) => {
                                this.props.cartStore.menuStore.horizontalMenuSelectedKey=item;
                                this.props.cartStore.menuStore.activePromo=index;
                                this.props.cartStore.menuStore.PromoMenuKey=index;
                                this.props.cartStore.menuStore.resetMeuList();
                                this.props.cartStore.menuStore.searchResult=this.props.cartStore.menuStore.filterMenuList;
                                var catsheader = document.querySelectorAll(".menuHeader");
                                catsheader[0].scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
                                
                                // Center the selected menu item
                                setTimeout(() => {
                                    const selectedItem = document.querySelector(".drawermenu.active");
                                    if (selectedItem) {
                                        selectedItem.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
                                    }
                                }, 100);
                                
                                this.props.onClose();
                                
                                // Center the selected item in the main menu after drawer closes
                                setTimeout(() => {
                                    const selectedItem = document.querySelector(".ant-menu-item-selected");
                                    if (selectedItem) {
                                        selectedItem.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
                                    }
                                }, 300);
                            }}
                            >{item}
                        </div>
                    )
                })}
            </React.Fragment>
        )
    }
    render() { 
	    let data=this.props.cartStore.menuStore.horizontalCategoryList;
        return (
            <React.Fragment>
                {this.renderCocoHorizontalPromos()}
                {/* {this.renderCocoHorizontalMenu(data)} */}
            </React.Fragment>
        )
    }
}

export default CocoMenuSide;