import React, { Fragment }  from "react";
import { observer, inject } from "mobx-react";
//import { Icon} from "antd";
import { Modal,Button } from "antd-mobile";
import "antd/dist/antd.css";
import { Collapse } from 'antd';
const alert = Modal.alert;
const { Panel } = Collapse;
@inject("cartStore")
@observer
class AddressInfo extends React.Component {
     confirmAddressChangePoup=()=>
	 {
		          alert(
						<span className="cartItem"></span>,
						<div dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(this.props.cartStore.menuStore.translation.change_delivery) }}/>,
						[
						  {
							text: this.props.cartStore.menuStore.translation.cancel
						  },
						  {
							text: this.props.cartStore.menuStore.translation.yes,
							onPress: () => {
							   localStorage.removeItem("is_opened_pre_question"); 
					           this.props.cartStore.menuStore.enablePreQuestionPopup();
							}
						  }
						]
					  );
	 }
    render() { 
       
		let deliveryType=this.props.cartStore.menuStore.getDeliveryType();
		let isDelievryOrPickup=this.props.cartStore.menuStore.checkIsPickUpOrDelivery();
		if(this.props.cartStore.menuStore.layout!==4)
		{
			return ('');
		}
		if(this.props.page==='payment' && isDelievryOrPickup)
		{
			return ( <Collapse>
				<Panel key="1" header={deliveryType.input_method==='Delivery' ? (<div>Delivery <Button
				onClick={() => {					
					 this.confirmAddressChangePoup();
				}}
				>Edit</Button></div>):(<div>Pick Up <Button
				onClick={() => {					
					 this.confirmAddressChangePoup();
				}}
				>Edit</Button></div>)}>
				<div className="userInfoDetails">			 
						{deliveryType.fullAddress}
			     </div>
				</Panel>				
			  </Collapse>);			  
		}
		else if(this.props.page==='menu')
		{  		  
        return (              
	         <Fragment>
			  {isDelievryOrPickup ? (
				<div className="del-address">
				{deliveryType.input_method==='Delivery' ? (<Fragment><strong>Delivery</strong> to</Fragment>):(<strong>Pick Up</strong>)}<br/>
				{deliveryType.fullAddress}				
				<span className="edit-address"
				onClick={() => {					
					 this.confirmAddressChangePoup();
				}}
				>Edit</span>
			  </div>):(
			   <div className="del-address">
			    <strong>Table # {this.props.cartStore.menuStore.getRealTagValue()}</strong>
				 </div>
			  )}             
           </Fragment>
        );
    }
	else
	{
		return ('');
	}
  }
}

export default AddressInfo;