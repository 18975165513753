function queryString(obj) {
    let qs = [];
    for (let key in obj) {
        qs.push(`${key}=${obj[key]}`);
    }
    return qs.join("&");
}

const Request = {
    get(url, params, headers = {}) {
        return new Promise(function (resolve, reject) {
            let qs = params === undefined ? "" : "?" + queryString(params);
            let r = new XMLHttpRequest();
            
            r.onreadystatechange = function () {
                if (r.readyState === 4) {
                    if (r.status >= 200 && r.status < 405) {
                        let response = r.responseText;
                        try {
                            response = JSON.parse(r.responseText);
                        } catch (err) { }
                        resolve(response);
                    } else {
                        console.log(r);
                        reject(r.responseText);
                    }
                }
            };
    
            r.open("GET", `${url}${qs}`, true);
    
            // Set headers
            for (const [key, value] of Object.entries(headers)) {
                r.setRequestHeader(key, value);
            }
    
            r.send();
        });
    },    
    getGZipApi(url, params) {
        return new Promise(function (resolve, reject) {
            let qs = params === undefined ? "" : "?" + queryString(params);
            let r = new XMLHttpRequest();
            r.open("GET", `${url}${qs}`, true);

            r.addEventListener('load', function (ev) {
                try {
                    const response = new window.Zlib.Gunzip(new window.Uint8Array(ev.target.response)).decompress();
                    let str = "";
                    for (let i = 0; i < response.byteLength; i++) {
                        str += String.fromCharCode(response[i]);
                    }

                    const res = JSON.parse(str);
                    resolve(res);
                }
                catch (err) {
                    reject({ error: 'outlet' });
                }
            });
            r.responseType = 'arraybuffer';
            r.send();

        });
    },
    post(url, params, headers = {}) {
        return new Promise((resolve, reject) => {
          let r = new XMLHttpRequest();
          r.open("POST", url, true);
      
          // Set headers
          for (const [key, value] of Object.entries(headers)) {
            r.setRequestHeader(key, value);
          }
      
          r.onreadystatechange = () => {
            if (r.readyState === 4) {
              if (r.status >= 200 && r.status < 400) {
                let response = r.responseText;
                try {
                  response = JSON.parse(r.responseText);
                } catch (err) {
                  console.error("Response parsing error:", err);
                }
                resolve(response);
              } else {
                console.error("Request failed with status:", r.status, r.responseText);
                reject(r.responseText);
              }
            }
          };
      
          r.send(params);
        });
      },      
      postJson(url, params, headers = {}) {
        return new Promise(function (resolve, reject) {
            let r = new XMLHttpRequest();
            r.open("POST", url, true);
    
            // Set headers
            for (const [key, value] of Object.entries(headers)) {
                r.setRequestHeader(key, value);
            }
            r.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    
            r.onreadystatechange = function () {
                if (r.readyState === 4) {
                    if (r.status >= 200 && r.status < 400) {
                        let response = r.responseText;
                        try {
                            response = JSON.parse(r.responseText);
                        } catch (err) {
                            console.error("Response parsing error:", err);
                        }
                        resolve(response);
                    } else {
                        console.error("Request failed with status:", r.status, r.responseText);
                        reject(r.responseText);
                    }
                }
            };
    
            r.send(JSON.stringify(params));
        });
    }    
}
export default Request;
