import React ,{ Fragment } from "react";
import { Modal, Button,Checkbox, InputItem } from "antd-mobile";
import { observer, inject } from "mobx-react";
import { SocialIcon } from "react-social-icons";
import SocialButton from "./SocialButton";
import SocialParser from "./SocialParser";
import PromptPopup from "../Popup";
import _ from "underscore";
import { FaUser} from "react-icons/fa";
import request from "../../libs/request";
import configParams from "../../libs/params";
import HeaderPopup from "../../components/HeaderPopup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const CheckboxItem = Checkbox.CheckboxItem;
const alert = Modal.alert;

@inject("cartStore")
@observer
class Login extends React.Component {
	  
	constructor(props) {
		super(props);
		this.state = {
		  step: "1",
		  pineapple_card_no: "",
		  pineapple_password: "",
		  phoneNumber: "",
		  countryCode: "",
		  ownerId: this.props.cartStore.menuStore.pineAppleOwnerkey,
		  closePopup: [],
		  promptPopup: false,
		  promptTitle: "",
		  promptContent: "",
		  promptLoading: true,
		  loginEmail: "",
		  loginPass: "",
		  isCheckingEber: 0,
		};
	  }	  
	 componentDidMount () {
        if (this.props.onMounted) {
            this.props.onMounted({
                reloadModal: res => this.reloadModal(res)
            })
		}
		this.setState({ isCheckingEber: 2});
		/*let cc = this.props.cartStore.menuStore.controller;
		let eberToken=localStorage.getItem(`${cc}_eber_token`);
         if(eberToken!=="" &&  isEberExistform )
		 {
			const config = {
			headers: { Authorization: `Bearer ${eberToken}` }
			}
			 this.setState({ isCheckingEber: 1});
     		axios.get(configParams.eberProfileUrl,config).then(res=>{
				
				let pData=res.data;
				this.props.cartStore.menuStore.rewardsList=[];
				let rData=[];
				if(pData.redeemable_list.length>0)
				{
                   rData=pData.redeemable_list;	
				}
               this.props.cartStore.menuStore.rewardsList=rData;				
				this.setState({ isCheckingEber: 0});
				this.props.cartStore.menuStore.isLoginModalVisible = false;
                this.props.cartStore.menuStore.openRewardListModal=true;
				let social= localStorage.getItem(`${this.props.cartStore.menuStore.controller}_social`);
				
				let pRes = { name: pData.display_name, eberId:pData.id ,  provider: 'eber',email: pData.email,username:pData.username,phone_code:pData.phone_code,phone:pData.phone,address:'',points:0,rewards:[]};
				
				if(pData.address && pData.address!=="")
				{
				pRes.address=pData.address;
				}
				if(pData.points && pData.points.length>0)
				{
				pRes.points=pData.points[0].points;
				}
				if(social!=="")
				{
					social=JSON.parse(social);
					if(social && social.eberId===pData.id)
					{
						pRes.rewards=social.rewards;
					}
				}
				this.props.cartStore.menuStore.saveSocial(pRes);
			 }).catch(err => {				  
				 this.setState({ isCheckingEber: 2});
				 console.log('error while eber login',err);
			 });
			 
		 }
         else
		 {
			 this.setState({ isCheckingEber: 2});
		 }*/			 
	 };
	reloadModal=(res)=>
	{
		//just reload the modal
	};
		
	eberButton = (appId) => {
		return (<Button
              className=""
			  key="eberbtn"
              onClick={() => {                  
			  
				  window.location.href="https://oauth.connectors.eber.io/o/oauth?client_id="+appId+"&redirect_uri="+configParams.eberRedirectUrl+"?controller="+this.props.cartStore.menuStore.controller;
              }}             
            >  
			  {this.props.cartStore.menuStore.translation.sign_in}</Button>);
	};	
	advocadoButton = (appId) => {
		return (
		  <Button
			className=""
			key="advocadoBtn"
			onClick={() => {
			  this.props.cartStore.menuStore.pineAppleOwnerkey = appId;
			  this.setState({ pineapple: true }, () => {
				this.handleAdvocadoLogin();
			  });
			}}
		  >
			{this.props.cartStore.menuStore.translation.sign_in}
		  </Button>
		);
	  };
	  handleSkipLogin = () => {
		this.props.cartStore.menuStore.byPassSocialLogin();
		console.log("Login skipped, continuing as guest...");
	  };
	  
  buttonGenerator = (provider, appId) => {
	 let responsebck=window.location.origin + window.location.pathname;
	 let cController=this.props.cartStore.menuStore.controller;
	 //let cController="demo";
	 
	 //localStorage.setItem('lastController',cController);
    if(provider==="instagram")
	{		
		responsebck=window.location.origin + '/bigbang?c='+cController;
		//responsebck=window.location.origin + '/bigbang?c=demo';
	}
	//console.log(responsebck);
	let btntext="continue_with_"+provider;
    return (
      <SocialButton
        key={provider}
        provider={provider}
		className={provider}
        appId={appId}
		redirect={responsebck}
        onLoginSuccess={(user, err) => {
			 //console.log(user);
          const parsed = SocialParser[provider](user);
          try {
             this.props.cartStore.menuStore.saveSocial(parsed);
			 var p=this.getQueryString('c',window.location.href);			 
			 this.props.cartStore.menuStore.sendGoogleAnalytics('login',provider, cController, 1);
			if(p!=="" && p!==null)
			{
				window.location = window.location.origin + '/'+p;
			}
          } catch (err) {
            console.log(err);
          }
        }}
        onLoginFailure={(user, err) => {
          //alert(err);
         // console.log(user);
          //console.log(err);
        }}
      >
	  {this.props.cartStore.menuStore.layout!==4 && <SocialIcon url="#" network={provider} />}<span>{this.props.cartStore.menuStore.translation[btntext]}</span>
      </SocialButton>
    );
  };
  generatePineAppleButton=(provider, appId)=>
  {
	  if(this.props.cartStore.menuStore.login_option==='1')
	  {		 
		  return ('');
	  }
	  else
	  {
		   return (
	        <span className="socialButton customWap" key="pineapplebtn" ><Button key="pineapplebtn" className="customBtn"
			 onClick={() => {                  
				  //this.pineapple=true;
				  //console.log('yess');
				  this.props.cartStore.menuStore.pineAppleOwnerkey=appId;
				  this.setState({ pineapple: true });
              }} 
			 ><span className="iconz"> <div className="ccle"><img src={configParams.pineAppleRoundImage} alt="Pineapple"/></div></span><span className='txt'>{this.props.cartStore.menuStore.translation.pineapple_btn_text}</span></Button></span>	  
	     );
	  }
  };
  generateAdvocadoButton = (provider, appId) => {
	if (this.props.cartStore.menuStore.login_option === "1") {
	  return "";
	} else {
	  return (
		<span className="socialButton customWap" key="pineapplebtn">
		  <Button
			key="pineapplebtn"
			className="customBtn"
			onClick={() => {
			  this.props.cartStore.menuStore.pineAppleOwnerkey = appId;
			  this.setState({ pineapple: true }, () => {
				this.handleAdvocadoLogin();
			  });
			}}
		  >
			<span className="iconz">
			  <div className="ccle">
				<img src={configParams.pineAppleRoundImage} alt="Pineapple" />
			  </div>
			</span>
			<span className="txt">
			  {this.props.cartStore.menuStore.translation.pineapple_btn_text}
			</span>
		  </Button>
		</span>
	  );
	}
  };
  

  renderButtons = () => {
	const list = [];
	const onlyEber = [];
	const onlyAdvocado = [];
  
	_.each(this.props.cartStore.menuStore.socialProviders, (providerConfig) => {
	  const keys = Object.keys(providerConfig);
	  console.log(keys);
	  if (keys[0] === "pineapple") {
		this.props.cartStore.menuStore.pineAppleOwnerkey = providerConfig[keys[0]];
		list.push(this.generatePineAppleButton(keys[0], providerConfig[keys[0]]));
	  } else if (providerConfig.advocado) {
		const button = this.advocadoButton(providerConfig.advocado.appId);
		list.push(button);
		onlyAdvocado.push(button);
	  } else if (!onlyAdvocado){
		list.push(this.buttonGenerator(keys[0], providerConfig[keys[0]]));
	  }
	});
  
	if (this.props.cartStore.menuStore.layout === 4) {
	  return onlyEber;
	}
  
	return list;
  };

    _renderLoginPromptPopup = () => {
    return (
      <PromptPopup
        visibility={this.state.promptPopup}
        title={this.state.promptTitle}
        closePopup={this.state.closePopup}
        //content={this._popupContent()}
        isLoading={true}
		 cartStore={this.props.cartStore}
      />
    );
  };

  handleAdvocadoLogin = async () => {
	const { phoneNumber, countryCode } = this.state;
	const controller = this.props.cartStore.menuStore.controller;
  
	if (!phoneNumber || !countryCode) {
	  alert("Country code and phone number are required", null, [
		{ text: "Close" },
	  ]);
	  return;
	}
  
	try {
	  // Step 1: Get Access Token without using Basic auth.
	  const tokenResponse = await request.post(
		configParams.advocadoTokenUrl,
		"grant_type=client_credentials",
		{
		  "Content-Type": "application/x-www-form-urlencoded",
		  controller: controller, // send controller header for device config lookup
		}
	  );
  
	  // Assume the response includes an 'expires_in' property (in seconds)
	  const tokenData = tokenResponse && tokenResponse.access_token;
	  const accessToken = tokenData && tokenData.value;
	  const expiresIn = tokenData && tokenData.expirationDate;
  
	  if (!accessToken || typeof accessToken !== "string") {
		throw new Error("Invalid access token");
	  }
  
	  // Calculate token expiry time (in milliseconds)
	  const expiryTime = Date.now() + parseInt(expiresIn, 10) * 1000;
	  // Store token and expiry time locally
	  localStorage.setItem(`${this.props.cartStore.menuStore.controller}_advocado_token`, accessToken);
	  localStorage.setItem(`${this.props.cartStore.menuStore.controller}_advocado_token_expiry`, expiryTime);
	  console.log("Advocado token saved:", accessToken);
  
	  // Step 2: Fetch Customer Information using the token
	  const customerResponse = await request.get(
		`${configParams.advocadoBaseUrl}/customers/phone-number/${countryCode}/${phoneNumber}/compact`,
		undefined,
		{
		  Authorization: `Bearer ${accessToken}`,
		  controller: controller, // include controller header
		}
	  );
  
	  if (customerResponse.code === 200) {
		const customerData = customerResponse.data;
  
		// Build rewards list from campaignBalances
		const rewardsList = customerData.campaignBalances.map((campaign) => ({
		  id: campaign.campaignId,
		  name: campaign.name,
		  description: campaign.description,
		  type: campaign.campaignType,
		  points: campaign.points || 0,
		  stamps: campaign.stamps || 0,
		  values: campaign.values || 0,
		  vouchers: campaign.vouchers || 0,
		  itemPrice: campaign.itemPrice || null,
		}));
  
		// Update rewardsList in the menuStore
		this.props.cartStore.menuStore.rewardsList = rewardsList;
  
		// Save enriched social data
		const enrichedData = {
		  ...customerData,
		  provider: "advocado",
		};
		this.props.cartStore.menuStore.saveSocial(enrichedData);
  
		alert("Login Successful!", null, [{ text: "Close" }]);
	  } else {
		throw new Error("Failed to fetch customer information");
	  }
	} catch (error) {
	  console.error("Advocado Login Error:", error);
	  alert(error.message || "An error occurred during login", null, [
		{ text: "Close" },
	  ]);
	} finally {
	  this.setState({ promptPopup: false });
	}
  };
  

 renderPineAppleModal=()=>{
	return (<Modal
                visible={this.state.pineapple}		
				transparent={true}
				maskClosable={false}
				animationType="slide-up"
				closable={false}
				//title={this.props.cartStore.menuStore.translation.pineapple_login_heading}
				className="App table_question pineappleModal"				
           >
 <div className="row heading"><img src={configParams.pineAppleLogo} alt="Pineapple"/><span>{this.props.cartStore.menuStore.translation.pineapple_login_heading}</span></div>
		   <div className="row borderTop">
					<div>
					<InputItem
					placeholder={this.props.cartStore.menuStore.translation.pineapple_card_no} 
					name="pineapple_card_no"
					type="text"
					id="pineapple_card_no"
					value={this.state.pineapple_card_no}
					onChange={(val) => this.setState({pineapple_card_no: val })}
					required
					/>
					</div>		  
					
					<div className="btnSetP">
					<Button
					className="class-btn option-list greytext"
					onClick={() => {                  

					this.setState({ pineapple: false });
					}} 
					>{this.props.cartStore.menuStore.translation.cancel}</Button>
					<Button
					className="class-btn option-list"
					onClick={() => {
						this.handleAdvocadoLogin();
					}}
					>
					{this.props.cartStore.menuStore.translation.pineapple_submit}
					</Button>

					
					</div>
			</div>	   
      </Modal>);
 };

 _renderOptionFirstModal = () => {
    return (
      <Modal
        visible={this.props.visible}
        style={{ overflow: "hidden", height: "100%", textAlign: "center" }}
        className="socialModal newModal"
      >
        <HeaderPopup changeLanguage={this.props.changeLanguage} />
        <div className="rewardWrap">
          <div className="formSection">
            <div>
				<div className="am-list-item am-input-item am-list-item-middle am-input-focus am-input-android">
					<div className="am-list-line">
						<div className="am-input-control">
							<PhoneInput
								country={"sg"}
								onChange={(value, data) => {
									const phoneNumberWithoutCountryCode = value.replace(`${data.dialCode}`, '').trim();
									this.setState({
										phoneNumber: phoneNumberWithoutCountryCode,
										countryCode: data.dialCode,
									});
								}}
								inputClass="am-input-control"
								inputStyle={{ width: "100%", height: "40px" }}
								dropdownStyle={{
									zIndex: 1050, // Ensure it's above other components
									position: "fixed", // Position it correctly
								}}
								containerStyle={{
									position: "relative", // Avoid overflow clipping
								}}
							/>
						</div>
					</div>
				</div>
			</div>

            <Button
              className="class-btn option-list"
              onClick={this.handleAdvocadoLogin}
            >
              LOGIN
            </Button>
			<div class="dotted-line"></div>
            <Button
              className="bypassbtn"
              onClick={() => this.props.cartStore.menuStore.byPassSocialLogin()}
            >
              CONTINUE AS GUEST
              <span> &gt;&gt;</span>
            </Button>
          </div>
        </div>
        {this._renderLoginPromptPopup()}
      </Modal>
    );
  };

  _renderOptionSecondModal=()=>{
	  return (		
      <Modal
        visible={this.props.visible}
        style={{ overflow: "hidden", height: "100%", textAlign: "center" }}
        className="socialModal oldmodal"
      >
        <div className="withoutLog">
		 {this.props.cartStore.menuStore.store.logo!==""?(
		  <div className="logoWrap">
		<img
            src={this.props.cartStore.menuStore.store.logo}
            alt={this.props.cartStore.menuStore.store.name}           
			  />
			   </div>
			   ):('')}
		 
		  <div className="btnSet">
          {this.renderButtons()}
		  </div>
		   <div dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(this.props.cartStore.menuStore.translation.login_html) }}  className="loginText"/>
		 
		   {this.props.cartStore.menuStore.store.hasWaiter?(		  
			<div className="btnSet">
			 <Button
              className="bypassbtn"
              onClick={() => {                  
				  this.props.callWaiterfunction();
              }}             
            > 
      	    <FaUser color={"#666"} size={16} />&nbsp;
			{this.props.cartStore.menuStore.translation
            .menu_option_call_waiter}</Button>
			</div>
			 ):('')} 
		  {this.props.cartStore.menuStore.isLoginWithoutEnabled===2?(
		  
			<div className="btnSet">
			 <Button
              className="bypassbtn"
              onClick={() => {                  
				  this.props.cartStore.menuStore.byPassSocialLogin();
              }}             
            >  
			{this.props.cartStore.menuStore.translation.continue_without_login}</Button>
			</div>
			 ):('')}
			<div className="poweredByLog">
			<p>Powered by</p>
			<a href={configParams.fzdomain}  target="_blank" rel="noopener noreferrer">   <img src={configParams.fzlogo}  alt="PunchPoint"  /></a>
			</div>			      
			     
          </div> 
		  {this.renderPineAppleModal()}
		   {this._renderLoginPromptPopup()}
      </Modal>
	  
    );
  }
  _renderLoginHome=()=>{
	  
	  return ( <div className="loginRegister loginhome">         
		 <img src={this.props.cartStore.menuStore.getLogo()} alt="logo" />
	  
	    <div className="buttonsection"> 
		  <div className="earnpoints"><span className="gifticon"></span><p style={{marginTop:"5.5px"}}>Earn points for rewards?</p></div>
			  {this.state.isCheckingEber===2 &&(
			   <Fragment>{this.renderButtons()}</Fragment>
			  )}
			   {this.state.isCheckingEber===1 &&(
			   <p style={{marginTop:"5.5px"}}>{this.props.cartStore.menuStore.translation.eber_checking}</p>
			   )}
			    
			<Button
              className="addpromolink"
              onClick={() => {                  
				  this.props.cartStore.menuStore.byPassSocialLogin();
              }}             
            >  
			{this.props.cartStore.menuStore.translation.continue_guest}</Button>
        </div>	  
	 
		{/*<div className="socialIcons">  
		<span className="facebook"></span><span className="instagram"></span><span className="youtubeicon"></span>
		</div>*/}
           	  
      </div>);
  }

  _renderCoCoSocial=()=>{
	   return (
	   <div className="bottomLine">
	   <div className="signinline"><span>{this.props.cartStore.menuStore.translation.or_sign_with}</span></div>
	   <div className="bottomIcons">  
	   {this.renderButtons()}
       </div></div>);
  }
  _renderCoCoHeader=()=>{
	  return (
	  <div className="info_header">
		<span className="backBtn"
		onClick={()=>{
			this.setState({step: '1' });
		}}
		>
		</span>
		<span className="logoclass">			
		<img src={this.props.cartStore.menuStore.getLogo()} alt="logo" style={{ height: "40px" }}
          //onClick={()=>{console.log(5555);}}
        />
		</span>
		<span className="homeclass">
		</span>
		</div>);
  }
  
  _renderOptionCocoModal=()=>{
	  return (	
      <Fragment>	  
      <Modal
        visible={this.props.visible}
       // style={{ overflow: "hidden", height: "100%", textAlign: "center" }}
        className="socialprompt"
      >	  
	   {this.state.step==='1' && (
	      <Fragment>{this._renderLoginHome()}</Fragment>
	    )}  
  	  
	  </Modal>
      </Fragment>	  
    );
  }
  render = () => {
	 // console.log(this.props.cartStore.menuStore.isLoginWithoutEnabled,'social login');
	 return (
	 <Fragment>
	 
	  {this.props.cartStore.menuStore.login_option==="1" && this.props.cartStore.menuStore.isEberLoginExist===false && (
	      <Fragment>{this._renderOptionFirstModal()}</Fragment>
	 )}
	 
	  {this.props.cartStore.menuStore.login_option!=="1" &&  this.props.cartStore.menuStore.isEberLoginExist===false && (
	      <Fragment>{this._renderOptionSecondModal()}</Fragment>
	 )}
	 </Fragment>);
	
  };
	getQueryString = function ( field, url ) {
		var href = url;
		var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
		var string = reg.exec(href);
		return string ? string[1] : null;
	};
}

export default Login;
