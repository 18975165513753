import React from "react";
import { Icon } from "antd-mobile";

class FoodStepper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onChange = value => {
    this.props.onChange(value);
  };

  minus = e => {
    if (this.props.value <= this.props.min || this.props.disabled === true) {
      return;
    }

    this.onChange(this.props.value - 1);
	if(this.props.screen!=="menuscreen" && this.props.screen!=="cart" && this.props.screen!=="addonitem")
	{
		this.props._getSetMealErrorMsg('minus');
	}
  };

  add = e => {
    if (this.props.value >= this.props.max || this.props.disabled === true) {
      return;
    }
	try{
		let neVal=this.props.value + 1;	
        if(this.props.screen==="menuscreen")
		{
			let rsp=this.props.checkQuanityError(this.props.cartStore,this.props.item,-neVal);
			//console.log(rsp,'rsp');
			if(rsp)
			{
				this.onChange(neVal);
			}
			
		}
		else{
			//console.log(this.props.screen);
			let nVal=-neVal;
			if(this.props.screen==="iteminfo")
			{
				nVal=neVal;
			}
			if(!this.props.isCustomize && this.props.steperPosition!=="itemInfoTop")
			{
				this.onChange(neVal);
				return;
			}			
			let validateSetMeals=this.props._getSetMealErrorMsg(nVal);
			//console.log(validateSetMeals,'rsp');
			if (validateSetMeals==="")
			{
			this.onChange(neVal);
			}
		}		
			
	}
	catch(e)
	{
		//this.onChange(this.props.value + 1);
	}
	
  };

  render() {
    return (
      <div>
        <div
          className={`food-stepper-container ${
            this.props.flexWidth === true ? "flexible" : ""
          }`}
        >
          <span
            onClick={this.minus}
            className={`food-stepper-select minus ${
              this.props.disabled === true || this.props.value <= this.props.min
                ? "food-stepper-select-disabled"
                : ""
            }`}
          >
            <Icon type="minus" style={{ width: "15px", height: "15px" }} />
          </span>
          <div className="food-stepper-number">{this.props.value}</div>
          <span
            onClick={this.add}
            className={`food-stepper-select plus ${
              this.props.disabled === true || this.props.value >= this.props.max
                ? "food-stepper-select-disabled"
                : ""
            }`}
          >
            <Icon type="plus" style={{ width: "15px", height: "15px" }} />
          </span>
        </div>
      </div>
    );
  }
}

FoodStepper.defaultProps = {
  min: 0,
  max: 99
};

export default FoodStepper;
