const translation = {
  menu: "Menu",
  cart: "Cart",
  order: "Order",
  close: "Close",
  success: "Success",
  cancel: "Cancel",
  confirm: "Confirm",
  search: "Search",
  retry: "Retry",
  report: "Report",
  error: "Error",
  detail: "Detail",
  set: "Set",
  x: "X",
  error_menu: "Please check internet connection and <a href='https://scan.foodzaps.com/'>scan the QR code</a> again. <br/>If the problem persists, pleaase report to https://www.foodzaps.com/support/",
  error_network: "Not able to connect to the server.\n Please check the internet connection and try again.",
  out_of_stock: "Out Of Stock",
  menu_success_response: "Your request has been sent successfully",
  menu_option_title: "Table No: ",
  menu_option_description: "Menu Ver: ",
  menu_option_call_waiter: "Call Waiter",
  menu_option_waiter_title: "Calling Waiter...",
  menu_option_call_bill: "Request Bill",
  menu_option_request_bill: "Requesting for bill...",
  menu_option_clear_past_order: "Clear Past Order",
  menu_option_language: "Language",
  menu_option_device_info: "Device Info",
  order_check_detail: "Checking order details",
  //queue_number: "outlet no: ",
  cart_title: "Cart",
  cart_empty: "Cart is empty, add something now!",
  cart_empty_prompt_title: "Cart Is Empty",
  cart_empty_prompt_info: "Please add something to your cart before checking out.",
  cart_check_order: "Select the Item(s)",
  cart_preparing_order: "Preparing your order",
  cart_processing_order: "Processing your order",
  verification: "Verification",
  verification_message: "Please enter verification code",
  verification_invalid:"Invalid Verification Code. Please check with the staff.",
  add_to_cart: "ADD TO CARD",
  add_more_set: "Add More Set",
  shop_close: "We are currently closed",
  shop_open: "Our business hours are ",
  checkout_response: "Your order has been processed. Your Queue No is ",
  checkout_message: "Order sent successfully, waiting for response from the outlet.",
  order_empty: "You have not ordered anything yet!",
  order_past_title: "Past Orders",
  order_queue_no: "No: ",
  order_check_again:"Please check again later as the outlet has not responded yet!",
  order_check_confirm:"Please proceed to counter for payment OR check with our staff!",
  order_expired: "Expired",
  order_expired_info: "Queue number has expired",
  order_pending: "Pending",
  order_success: "Success",
  order_accepted : "Accepted",
  order_error: "Error",
  order_rejected: "Rejected",
  order_waiting_confirm: "Waiting Confirmation",
  option_fail: "Fail", 
  option_fail_content: "Oops! Failed to send your request, please approach the staff.",
  option_select_language: "Select language",
  option_select_language_description: "Choose one",
  option_reset_order: "Reset Order",
  option_reset_order_confirmation: "Are you sure you want to clear your order list?",
  clear_pending_title: "Reset Order",
  clear_pending_msg: "Are you sure you want to clear your order list?",
  option_reset_complete: "Orders cleared",
  price_zero: "Free",
  check_later: "Check Later",
  ok:"Ok",
  normal_full_screen:"Exit Full Screen",
  full_screen:"Full Screen",
  continue_without_login:"Skip Login",
  continue_with_facebook:"FACEBOOK",
  continue_with_google:"GOOGLE",
  continue_with_instagram:"INSTAGRAMy",
  login_html:"<strong>Why do I need to Login In?</strong><p>This will help the merchant (e.g. restaurant's owner) to speed up the verification and ordering process.</p><p>If you are not using your own personal device to place order, select [ Skip Login ].</p><p>Your username and password will never leave your social website (Facebook, Google).<br>PunchPoint only request for name and contact information from your social website.</p>",
  scan:"Scan",
  remarks:"Enter Remarks",
  take_away:"Select to take away.",
  clear_pending_order_all:"Clear All",
  opt_selection_title:"Select Special Request",
  in_vaild_error:"Something went wrong. Please try again using another payment type.",
  redirect_payment:"Redirecting to Payment Gateway...",
  payment_status:"Payment Status", 
  paypal_payment_msg:"The payment has been cancelled.",
  repeat_order:"Repeat Order",
  no_internet:"Please check the internet connection and try again.",
  not_available:"Not Available Today",//no
  not_available_msg:"Some of items are not available now. Please remove them.",//no
  social_login_offer:"Login with Facebook and Google for Pineapple Points!",
  checkbox_permission_text:"Allow to use your name and email to auto-register membership account",
  pineapple_btn_text:"Pineapple",
  pineapple_card_no:"Card No",
  pineapple_password:"Pin",
  pineapple_submit:"SIGN IN",
  pineapple_error:"Invalid Details",
  pineapple_login_heading:"Pineapple Login",
  pineapple_login_checking:"Verifying....",
  powered_by:'Powered by',
  scan_message:'To place order, SCAN the QR Code again.',
  enter_special_request:'Enter Special Request',
  required_fields:'Please fill in all the required info.',
  invalid_email:'Invalid Email',
  required_text:'Required',  
  back: "Back",
  email: "Email",
  name: "Name",
  contact: "Mobile No",
  personal_info:"Contact Info",
  send_emil:'Report Issue',
  email_sent:'Email Sent',
  email_error:'Email failed.',
  //unexpected_error:'Encountered Unknown Error',
  no_reply_outlet:'The outlet is busy or offline. Do you want to try again?',
  no:'No',
  yes:'Yes',
  checking_order_with_outlet:'Checking the order with outlet.',
  payment_preview:'Payment Preview',
  pay_now:'Pay Now',
  proceed_now:'Proceed Now',
  social_connect:"Or Connect With",
  outlet_not_found:"Outlet not found",
  next:"Next",
  exit:"Exit",
  more:"Add More",
  add:"ADD",
  order_waiting: "Waiting",
  scan_again:'Invalid link! Please scan the QR code again.',
  check_last_order:'Remind Order',
  new_order:"New Order",
  last_order:"Remind Order",
  change_table:"Change Table",
  checking:"Checking",
  waiting_respond_outlet:"Waiting for respond from the outlet.",
  status_no:"Status - No ",
  check_item_error:"Please add items to check the status.",
  allow:"Allow",
  welcome_back:"Welcome Back!",
  processing_payment:"Processing Payment",
  payment_error_title:"Payment Error",
  general_error:"Sorry, we have encountered unexpected error (#ERROR CODE#) while processing your order. The tracking order no is #Order ID#.  Please try again later.",
  payment_error:"<p>Your payment didn't go through.<br/>Please try a different payment method or try again later.<br/><br/>Common causes:<br/>- Canceled by you<br/>- Low card balance<br/>- Invalid credit card number<br/>- Expired card<br/>- Declined by the issuing bank. </p><p>Error code is #ERROR CODE# and tracking order no #Order ID#.</p>",
  gateway_error:"The payment gateway has reported #MESSGE# and we are not able to process your order. The tracking order no is #Order ID#.",
  payment_cancel:"You have cancelled the payment and we are not able to process your order.",
  repeat_order_btn:"Repeat",
  last_order_btn:"Remind Order",
  logout:'Logout',
  check_bill_btn:"Check Current Order",
  request_bill_error:"Request Bill Error",
  request_bill_error_msg:"No bill to request. For the past few hours, you didn't place any order.",
  checkoing_current_order:"Requesting for current order",
  share:"Share",
  copy:"Link copied to clipboard.",
  try_again:"Try Again",
  server_id_error:"No respond from the server.\nPlease check the internet connection and try again.",
  add_cart:"ADD",
  invalid_outlet_message:"People who access to online menu, should not have access to Outlet order",
  invalid_outlet_title:"Invalid Items",
  no_recommendation:"No Recommendation Found",
  login:"Login",
  register:"Register",
  checkout:"Checkout",
  proceed:"Confirm",
  cart_hungry:"Hungry?",
  coco_empty_cart:"You haven't added anything to your cart!",
  browse:"Browse",
  my_cart_title:"My Cart",
  recommended:"Recommended",
  add_more_item:"Add More Items",
  clear_cart:"Clear Cart",
  clear_cart_message:"Are you sure you want <br/> to clear your cart?",
  re_order:"Order Again",
  change_delivery:"Are you sure to change<br/> delivery or pick up option?",
  search_placeholder:"Search for a specific food",
  make_another:"Make another",
  sign_in:"Sign In",
  create_account:"Create Account",
  continue_guest:"Continue as Guest",
  password_text:"Password",
  or_sign_with:"OR SIGN IN WITH",
  forget_password:"Forgot Password?",
  full_name:"Full Name",
  phone_number:"Phone",
  delete_account:"Delete my account",
  not_available_now:"Not Available Now",//no
  delete_item:"Are you sure you want <br/> to remove item from your cart?",//no
  update_item:"Update",
  rewards:"Rewards",
  eber_checking:"We are checking your login details.",
  term_condition: 'T&C',
  privacy_policy: 'Privacy Policy',
  return_policy: "Return Policy",
  faq:"FAQ",
  invalid_bwc_membership:"You dont have membership. So please refresh the page or try again.",
  invalid_redeem_code:"Redeem code is empty. So we can't use this payment gateway.",
  less_redeem_amount:"Voucher is greater than total bill. Please either add more items to your cart or remove and select lower value voucher",
  change_voucher:"Change Voucher",
  add_new_items:"Add to Order",
  refund_start:"Refund Process in progress",
  refund_success:"Amount refunded successfully",
  refund_failed:"Refund process failed. Please contact to support team",
};


export default translation;
