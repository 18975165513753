import React, { Component, Fragment } from "react";
import API from "../../libs/api";
import { Icon,Modal, Button} from "antd-mobile";
import StripeCheckout from 'react-stripe-checkout';
import PaypalCheckout from "./PaypalCheckout";
import { observer,inject } from "mobx-react";
import { Collapse } from 'antd';
import AddressInfo from ".././AddressInfo";
//import moment from "moment";
import request from "../../libs/request";
import PromptPopup from ".././Popup";
import _ from "underscore";
import configParam from "../../libs/params";
import PowerBy from "../PowerBy";
import {RenderAddon,RenderNotes } from "../Addon";
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
const alert = Modal.alert;
const PageTimeout = 780;
const { Panel } = Collapse;
//const domianUrl = "https://gcstagpayverify.foodzaps.com/";
//const testPaymentUrl = "https://gpaymenu.foodzaps.com/extra/test-payment.php";

@inject("cartStore")
@observer
class PaymentOption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            closePopup: [],
            promptPopup: false,
            promptTitle: "",
            promptContent: "",
            promptLoading: false,
            errors: '',

            isPaying: false,
            email: this.props.cartStore.menuStore.social.email,
            name: this.props.cartStore.menuStore.social.name,
            contact: this.props.cartStore.menuStore.social.phone,
            activePopup: '',
            isVisiableReciept: false,
            total: this.props.total,
            data: { res: { receipt: "{}", lastMessage: '' } },
        };

    };
    componentDidMount() {
            if (this.props.onMounted) {
                this.props.onMounted({
                    openSinglePaymentGateway: singleP => this.openSinglePaymentGateway(singleP)
                })
            }
        };
        /* componentDidMount() {
	   this._setupUpcomingDays();
	 }
	 _setupUpcomingDays = () => {
	   let dates = [];
	   let counter = 0;
   
	   let now = moment();
	   while (counter !== this.props.cartStore.menuStore.collectionAdvanceDay) {
		 // 0 = sunday, 6 = saturday in moment
		 // check date if exist in operation days
		 if (
		   this.props.cartStore.menuStore.operationDay.indexOf(
			 String(now.day() + 1)
		   ) !== -1
		 ) {
		   dates.push({
			 label: now.format("MMM D"),
			 value: now.format("YYYYMMDD")
		   });
   
		   counter++;
		 }
		 now.add("days", 1);
	   }
	   this.setState({ upcomingDays: [dates] });
	 };*/

    /* _renderCreditCard = () => {
    	 //console.log(this.props.cartStore.menuStore.stripeKey);
    	 //"pk_test_TYooMQauvdEDq54NiTphI7jx"
    	 if(this.props.cartStore.menuStore.stripeKey)
    	 {
       return (
    	 <List.Item id="creditcard"  className={this.state.paymentType==='stripe'?"":" hideDiv"}>
    	   <StripeProvider apiKey={this.props.cartStore.menuStore.stripeKey}>
    		 <div className="stripe">
    		   <Elements>
    			 <CheckoutForm setClick={click => (this.clickChild = click)} />
    		   </Elements>
    		 </div>
    	   </StripeProvider>
    	 </List.Item>
       );
    	 }
    	 else{
    		 return ('');
    	 }
     };*/

    /*  _renderOptions = () => {
    	return (
    		 <List
    		  renderHeader={() => (
    			<div className="paymentHeader">Pickup & Payment</div>
    		  )}
    		  className={this.state.orderStep===2?"popup-list":"popup-list hideDiv"}
    		> 
	
    		  {this._renderCreditCard()}
	
    		  <List.Item>
    			<Button
    			  type="primary"
    			  onClick={() => {
    								this.setState({ isPaying: true });
    				if (this.state.paymentType === "stripe") { 
    				  this.clickChild(this.props.total * 1000, "SGD").then(
    					async token => {
    					  request
    						.get(
    						  window.location.protocol +
    							"//" +
    							window.location.host +
    							"/stripe.php?token=" +
    							token+"&pk="+this.props.cartStore.menuStore.stripeKey
    						)
    						.then(res => {
    						  //const time = moment(this.state.time).format("HHmm00");
    						  //const payload = `paymentType=stripe&paymentAmount=${this.props.total}&transactionId=${res.id}`;
    						  
    						   const payload = 'payment={"method":"stripe","paymentAmount":"'+this.props.total+'","transactionId":"'+res.id+'"}';
	
    						  this.props.cartStore
    							.checkout(null, payload)
    							.then(res => {
    								this.props._callCartStatusCheck(1);	
    							  this.setState({ isPaying: false,orderStep:1 }, () => {
    							  
    								
    							  });
    							  this.props.cartStore.menuStore.showPaymentOption=false;
    							  this.props.cartStore.tabHandler("order");
    							  this.props._callCartStatusCheck(res);							  
    							});
    						})
    						.catch(err => {
    						  this.setState({ isPaying: false });
    						  err = JSON.parse(err);
    						  alert(err.msg.message, null, [
    							{ text: "Close", onPress: () => {} }
    						  ]);
    						});
    					}
    				  );
    				} 				
    				else {
    				  alert("Demo version, not implemented yet");
    				}
    			  }}
    			  disabled={this.state.isPaying}
    			  loading={this.state.isPaying}
    			>
    			  Pay ${this.props.total}
    			</Button>
    		  </List.Item>
    	    
    		 <List.Item>
    			 <div className="themeBtn"
    			  onClick={() => {
    				   this.setState({ paymentType: '',orderStep:1 });
    			}}
    			>Cancel</div>
    	   </List.Item>
    	  </List> 		
    	);
      };*/
	 _getValueByKey = (keyname) => {
        let savedPostQ = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_postQuestions`);
        if (savedPostQ) {
            let fieldsAll = JSON.parse(savedPostQ);
			return fieldsAll[keyname];
		   }
		   return '';
		};			
    _setDefaultDetails = () => {
        let savedPostQ = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_postQuestions`);
        if (savedPostQ) {
            let fieldsAll = JSON.parse(savedPostQ);
			let state=this.state;
            if (fieldsAll.input_name !== "undefined")
			{
				state.name=fieldsAll.input_name;
			}
			
			if(fieldsAll.input_email !== "undefined")
			{
				//console.log('fieldsAll.input_email',fieldsAll.input_email);
				state.email=fieldsAll.input_email;
                 //console.log(state.email,fieldsAll.input_email);
			}
			if(fieldsAll.input_contact !== "undefined")
			{
				//console.log('contac');
                state.contact=fieldsAll.input_contact;

            }
			if(state.email=== "undefined")
			{
				state.email='';
			}
			//else {
				// console.log('errrrr');
                // state.errors=this.props.cartStore.menuStore.translation.in_vaild_error;
            // }
			
			this.setState(state);
			//console.log(state,'state',savedPostQ);
        }
    };
    getPaymentDomainUrl = (payment) => {
        let serverUrl = payment.server;
        serverUrl = serverUrl !== undefined ? serverUrl : configParam.verificationServer;
        if (serverUrl.charAt(serverUrl.length - 1) !== '/') {
            serverUrl = serverUrl + '/';
        }
        return serverUrl; 
    };
    openSinglePaymentGateway = (payment) => {
		 if (payment === 'nogateway') {
			 
			if(this.props.cartStore.menuStore.isSpotMethod())
			{
            let orderObj=this.spotOrderObject(true);
            console.log("Spot Post Paid OrderObj", orderObj)
			this.props.cartStore.menuStore.spotOrderObj=orderObj;			
			}
			this.props._callCartStatusCheck(payment);   
            return;			
        }
		
        let gateway = payment.gateway;
        this.setState({ data: { res: { receipt: "{}", lastMessage: '' },payment:payment }, isVisiableReciept: false, total: 0, paymentType: gateway });
        
        if (gateway === 'cash' && this.props.cartStore.menuStore.layout!==4) {
            this.handleCashPayment();
        } else {
            //for t05
            let dAll = {};
            dAll.payment = payment;
            this.handlePaymentRequest(dAll);
        }
        this.props.cartStore.menuStore.showPaymentOption = false;

    };
    handlePaymentRequest = (data) => {

       // localStorage.removeItem('receipt_id'); //commented for bwc
        this._setPopupForMerchantStatus();
        this.props._callMerchantCreateOrderApi('').then(res => {

                if (res.orderKey && res.orderKey !== '') {
                    this.checkPaymentRequest(data, res.orderKey);
                } else {
                    //console.log(res.orderKey,'else');
                    let msg=(!navigator.onLine?this.props.cartStore.menuStore.translation.no_internet:this.props.cartStore.menuStore.translation.server_id_error);
                    this.setState({
                        promptPopup: true,
                        promptLoading: false,
                        promptContent: msg,
						closePopup: [{
									text: this.props.cartStore.menuStore.translation.try_again,
									onPress: () => {
									this.setState({ promptPopup: false, paymentType: "", activePopup: '' });
									this.handlePaymentRequest(data);
						           }},
								   {text: this.props.cartStore.menuStore.translation.close,
									onPress: () => {
									this.setState({ promptPopup: false, paymentType: "", activePopup: '' });
									}
								}]
                    });
                }

            })
            .catch(err => {
                this.handleError();
                console.log(err);
            });
    };
    _setPopupForMerchantStatus = () => {
        this.setState({
            promptPopup: true,
            promptLoading: true,
            promptContent: this.props.cartStore.menuStore.translation.checking_order_with_outlet,
            closePopup: []
        });
    };
    _setPopupForPaymentStatus = (pGateway) => {
        this.setState({
            activePopup: pGateway.gateway,
            promptPopup: true,
            promptLoading: true,
            promptContent: this.props.cartStore.menuStore.layout===4?"Redirecting you to payment gateway...":this.props.cartStore.menuStore.translation.processing_payment + " " + pGateway.name,
            closePopup: this._closePaymentPopup()
        });
    };
	_closePaymentPopup=()=>{
		return [{
                text: this.props.cartStore.menuStore.translation.close,
                onPress: () => {
                    this.setState({ promptPopup: false, paymentType: "", activePopup: '' });
                    //obj._callCartStatusCheck(2);
                }
            }];
	}
    checkPaymentRequest = (data, orderKey) => {
        //this.setState({ activePopup:'' });
        //console.log(55);
        let cancelCheckStatus = null;
        let countdownTimer = setInterval(() => {
            //console.log('timerrr');
            this.props._callMerchantCreateOrderApi(orderKey).then(res => {
                    // clearInterval(countdownTimer); 
                    if (res.status === "1" || res.status === "-2") {
                        clearTimeout(cancelCheckStatus);
                        clearInterval(countdownTimer);
                        data.res = res;
                        //this.props._callCartStatusCheck(2);	
                        this.setState({
                            promptPopup: false,
                        }, () => {
                            if (res.success === true) {
								localStorage.setItem('receipt_id', orderKey);
                                data.receipt_id=orderKey;
                                localStorage.setItem(`${this.props.cartStore.menuStore.controller}_receipt`, res.receipt);
                                let receipt = JSON.parse(res.receipt);
                                this.setState({ data: data, isVisiableReciept: true, total: receipt.total });
                                //this.setState({promptPopup:false});

                            } else {

                                alert(res.lastMessage, null, [{
                                    text: this.props.cartStore.menuStore.translation.ok,
                                    onPress: () => {
                                    }
                                }]);

                            }
                        });
                    }
                })
                .catch(err => {
                    clearTimeout(cancelCheckStatus);
                    clearInterval(countdownTimer);
                    this.handleError();
                    console.log(err);
                });

        }, 1000);


        cancelCheckStatus = setTimeout(() => {
            clearTimeout(cancelCheckStatus);
            clearInterval(countdownTimer);			  
            this.setState({
                promptPopup: true,
                promptLoading: false,
                promptContent: this.props.cartStore.menuStore.translation.no_reply_outlet,
                closePopup: [{
                        text: this.props.cartStore.menuStore.translation.no,
                        onPress: () => {
                           
                            this.setState({
                                promptPopup: false,

                            });
							//this.props.cartStore.menuStore.showPaymentOption = true;
                        }
                    },
                    {
                        text: this.props.cartStore.menuStore.translation.yes,
                        onPress: () => {
                            this.checkPaymentRequest(data, orderKey);
                            this.setState({ promptPopup: false, });
                            this._setPopupForMerchantStatus();
                        }
                    }
                ]
            });

        }, 30000);

        /*this.setState({
        cancelCheckStatus: cancelCheckStatus,  
        countdownTimer: countdownTimer,  

        });*/

        /* this.props._callMerchantCreateOrderApi().then(res => {
        	   //console.log(res);
        	   this.props._callCartStatusCheck(1);
        	   data.res=res;
        	   if(res.success===true)
        	   {		
        			 localStorage.setItem(`${this.props.cartStore.menuStore.controller}_receipt`,res.receipt);
        			  let receipt=JSON.parse(res.receipt);
        			 this.setState({ data: data,isVisiableReciept:true,total:receipt.total});
        			    
        	   }
        	   else{
        		   alert(res.lastMessage, null, [
        						 { text: this.props.cartStore.menuStore.translation.ok, onPress: () => {	
        						   this.props._callCartStatusCheck(2);								
        							 // this.props.cartStore.menuStore.showPaymentOption=true;
        						 } }
        						 ]);
        	   }
        	 })
        	 .catch(err => {
        	   this.handleError();
         });*/
    };
    handleEGHLPayment = () => {
        let data = this.state.data;
        let payment = data.payment;
        this._setPopupForPaymentStatus(payment);
        let serverUrl = this.getPaymentDomainUrl(data.payment);
        let returnUrl = data.returnUrl;
        let amt = this.state.total;
        let PaymentID = data.res.key;
        request.get(serverUrl + "eghl-generate-hash?ServiceID=" +
                payment.appid + "&CurrencyCode=" + this.props.cartStore.menuStore.currencyCode + "&Amount=" + amt + "&MerchantReturnURL=" + returnUrl + '&PageTimeout=' + PageTimeout + '&PaymentID=' + PaymentID
            )
            .then(res => {

                if (this.state.paymentType === 'eghl') {
                    //	this.setState({ promptPopup: false,paymentType: 'eghl', activePopup: 'eghl' },()=>{
                    let cId = this.props.cartStore.menuStore.controller + '--' + PaymentID;
                    localStorage.setItem(`${this.props.cartStore.menuStore.controller}_verifyserver`, serverUrl);
                    localStorage.setItem(`${this.props.cartStore.menuStore.controller}_partnerTX`, cId);
                    localStorage.setItem(`${this.props.cartStore.menuStore.controller}_accessToken`, payment.appid);
                    localStorage.setItem(`${this.props.cartStore.menuStore.controller}_sandbox`, (payment.sandbox ? payment.sandbox : 0));

                    document.getElementById("OrderNumber" + payment.sandbox).value = PaymentID;
                    document.getElementById("PaymentID" + payment.sandbox).value = PaymentID;
                    document.getElementById("CustIPeGHL" + payment.sandbox).value = res.CustIP;
                    document.getElementById("HashValue" + payment.sandbox).value = res.HashValue;
                    document.getElementById("eghlform" + payment.sandbox).submit();
                    //	});
                }
            })
            .catch(err => {
                this.handleError();
                console.log(err);
            });

    };
    handleAliPayment = () => {

        let data = this.state.data;
        let payment = data.payment;
        this._setPopupForPaymentStatus(payment);
        let serverUrl = this.getPaymentDomainUrl(payment);
        let amt = this.state.total;
        let orderId = data.res.key;
        let cUrl = window.location.href;
        let afterUrl = serverUrl + "alipay-redirect?c=" + cUrl;
        let cancelUrl = serverUrl + "alipay-cancel?c=" + cUrl;
        let notifyUrl = serverUrl + "alipay-notify?c=" + cUrl;
        request
            .get(serverUrl + "alipay-generate-hash?orderId=" + orderId + "&amount=" + amt + "&afterUrl=" + afterUrl + "&cancelUrl=" + cancelUrl + "&notifyUrl=" + notifyUrl + "&_createTime=" + data.res._createTime + "&controller=" + this.props.cartStore.menuStore.controller + "&sandbox=" + payment.sandbox)
            .then(res => {
                //console.log(this.state.paymentType);
                if (this.state.paymentType === 'alipay') {
                    //this.setState({ promptPopup: false,paymentType: 'alipay', activePopup: '' },()=>{
                    //localStorage.setItem(`${this.props.cartStore.menuStore.controller}_verifyserver`, serverUrl);
                    //localStorage.setItem(`${this.props.cartStore.menuStore.controller}_partnerTX`, orderId);
                    if (payment.sandbox === "test") {
                        window.location.href = configParam.fzTestPaymentUrl + "?p=alipay";
                    } else if (res.url !== "") {
                        window.location.href = res.url;
                    } else {
                        this.handleError();
                    }

                    //});
                }

            })
            .catch(err => {
                this.handleError();
                console.log(err);
            });
    };
	openAdyenPaymentPopup = (resp,orderno,amt) => {
		let data = this.state.data;        
        let payment = data.payment;
		let orderId = data.res.key;
		let obj=this;
		let snInt=parseInt(payment.sandbox);
		
		const applePayConfiguration = {
		amount: {
		value: amt,
		currency: payment.currency
		},
		//Set this to PRODUCTION when you're ready to accept live payments
		environment: snInt===1?'test':'live',
		};
		let paymentConfig={
						card: {
						hasHolderName: true,
						holderNameRequired: true,
						billingAddressRequired: false
						}
					};
			if(payment.paymentenable && payment.paymentenable.apple && (payment.paymentenable.apple===1 || payment.paymentenable.apple==='1'))
			{
				paymentConfig.applepay=applePayConfiguration;
			}				
			console.log('paymentConfig',paymentConfig);
			
		const configuration = {
					environment: snInt===1?'test':'live', // Change to one of the environment values specified in step 4.
					clientKey: payment.appid, //"test_PTD36OSAMJE27CALJG5XLL6AK4ZQYUQA",//payment.appid, 
					analytics: {
					enabled: true // Set to false to not send analytics data to Adyen.
					},
					showPayButton: true,
					shopperInteraction:"Ecommerce",
					session: {
					id: resp.id, // Unique identifier for the payment session.
					sessionData: resp.sessionData // The payment session data.
					},
					onPaymentCompleted: (result, component) => {
						let failedArray=["refused","cancelled"];
						let successArray=["authorised"];
						this.setState({ activePopup: ""});
						if(failedArray.indexOf(result.resultCode.toLowerCase())>-1)
						{
							
							alert(result.resultCode, "The payment has been "+result.resultCode+". Do you want to try again", [
							{
							text: this.props.cartStore.menuStore.translation.no,
							onPress: () => {
							}
							},
							{ 
							 text: this.props.cartStore.menuStore.translation.yes, 
								onPress: () => {
                                  this.handleAdyenPayment();									
								//this.props._callCartStatusCheck(2);								
								// this.props.cartStore.menuStore.showPaymentOption=true;
								} 
							  }
							]);
						}else if(successArray.indexOf(result.resultCode.toLowerCase())>-1)
						{
							var resData={};
							resData.pingUrl = configParam.apiAddOrder+"/getPing/"+orderId;
							resData.method = payment.gateway;
							resData.status = 'success';
							resData.refno = orderId;
							 this.props._callCartStatusCheck(1);
							this.props._callPaypalPaymentStatus(resData,'redirect');
							console.log(orderno,'ordernoordernoordernoorderno');
							
						}
					    console.info(result, component);						
					},
					onError: (error, component) => {
					console.error(error.name, error.message, error.stack, component);
					},
					// onAdditionalDetails:(state, component)=>{
						// console.log(state, component,'hkhkhhkhkhkhk');
					// },
					paymentMethodsConfiguration: paymentConfig
					};
					
		        this.setState({ promptPopup: false,activePopup: 'adyen'},()=>{
                (async() => {
					const checkout = await AdyenCheckout(configuration);
					// Access the available payment methods for the session.
					console.log(checkout.paymentMethodsResponse); 
				const dropin = checkout.create('dropin', {
							instantPaymentTypes: ['applepay']

							}).mount('#dropin-container');
					})();
				
                setTimeout(function(){
					obj.setState({ adyenContent: ""});
				}, 5000);				
					
         });
		
	};
	handleAdyenPayment = () => {
		let data = this.state.data;
       // this._setPopupForPaymentStatus(payment);
        let payment = data.payment;
		this.props._callCartStatusCheck(1);
        let serverUrl = this.getPaymentDomainUrl(payment);
        let amt = this.state.total;
        console.log("Amount", amt)
        // amt = parseFloat(amt).toFixed(2);
        // amt = parseInt((amt * 100 / 100) * 100);
        // amt = parseInt((amt).toString().replace(".", ""))
        amt = parseInt((parseFloat(amt).toFixed(2)).toString().replace(".", ""))
        let orderId = data.res.key;
		let controllerID = this.props.cartStore.menuStore.store.controller;
        //let controllerName = this.props.cartStore.menuStore.controller;
		//let cUrl=window.location.protocol + "//" +window.location.host+ "/" + controllerName;
        let cId = orderId + '--' + payment.sandbox+ '--' + controllerID;
        //if (controllerID !== controllerName) {
        //    cId = cId + '--' + controllerID;
        //}  

        cId = btoa(cId);
		
        let afterUrl = serverUrl + "adyen-redirect?customOrderId=" + cId;
        let cancelUrl = serverUrl + "adyen-cancel?customOrderId=" + cId;
        let notifyUrl = serverUrl + "adyen-webhook?customOrderId=" + cId;
        request
            .get(serverUrl + "adyen-generate-hash?orderId=" + cId + "&amount=" + amt +"&controller=" + this.props.cartStore.menuStore.controller +"&notifyUrl="+notifyUrl +"&sandbox=" + payment.sandbox+"&afterUrl=" + afterUrl+"&cancelUrl=" + cancelUrl)
            .then(res => {
                //console.log(this.state.paymentType);
                if (this.state.paymentType === 'adyen') {
                    if (res.sessionData && res.sessionData !== "") {						
				    this.openAdyenPaymentPopup(res,cId,amt);
					this.props._callCartStatusCheck(2);
					// window.location.href = configParam.adyenRedirectPaymentUrl + "?sessionId="+encodeURIComponent(res.sessionData)+"&name="+this.props.cartStore.menuStore.store.name+"&order="+encodeURIComponent(res.id)+"&errorUrl="+cUrl+"&sandbox=" + payment.sandbox+"&afterUrl=" + encodeURIComponent(afterUrl);
                    } else {
						this.props._callCartStatusCheck(2);
                        this.handleError();
                    }
                }

            })
            .catch(err => {
				this.props._callCartStatusCheck(2);
                this.handleError();
                console.log(err);
            });
    };
	handleDBAPayment = () => {

        let data = this.state.data;
        let payment = data.payment;
        this._setPopupForPaymentStatus(payment);
        let serverUrl = this.getPaymentDomainUrl(payment);
        let amt = this.state.total;
        let orderId = data.res.key;
		let controllerID = this.props.cartStore.menuStore.store.controller;
        let controllerName = this.props.cartStore.menuStore.controller;
		let cUrl=window.location.protocol + "//" +window.location.host+ "/" + controllerName;
        let cId =  cUrl+ '--' + orderId + '--' + payment.sandbox;
        if (controllerID !== controllerName) {
            cId = cId + '--' + controllerID;
        }        
        cId = btoa(cId);
		
        let afterUrl = serverUrl + "dba-redirect?customOrderId=" + cId;
        let cancelUrl = serverUrl + "dba-cancel?customOrderId=" + cId;
        let notifyUrl = serverUrl + "dba-webhook?customOrderId=" + cId;
        request
            .get(serverUrl + "dba-generate-hash?orderId=" + orderId + "&amount=" + amt +"&controller=" + this.props.cartStore.menuStore.controller +"&notifyUrl="+notifyUrl +"&sandbox=" + payment.sandbox+"&afterUrl=" + afterUrl+"&cancelUrl=" + cancelUrl)
            .then(res => {
                //console.log(this.state.paymentType);
                if (this.state.paymentType === 'dbs') {
                    if (res.sessionId && res.sessionId !== "") {
						window.location.href = configParam.dbaRedirectPaymentUrl + "?sessionId="+res.sessionId+"&name="+this.props.cartStore.menuStore.store.name+"&order="+orderId+"&errorUrl="+cUrl+"&sandbox=" + payment.sandbox;
                    } else {
                        this.handleError();
                    }
                }

            })
            .catch(err => {
                this.handleError();
                console.log(err);
            });
    };
    handleIPay88Payment = () => {

        let data = this.state.data;
        let payment = data.payment;
        this._setPopupForPaymentStatus(payment);
        let serverUrl = this.getPaymentDomainUrl(payment);
        let amt = this.state.total;
        let hashAmt = amt.toString().replace('.', '');
        let PaymentID = data.res.key;
        this._setDefaultDetails();
		let controllerID = this.props.cartStore.menuStore.store.controller;
		this.setState({ promptPopup: false,activePopup: 'ipay88'},()=>{
        request
            .get(serverUrl + "ipay88-generate-hash?Amount=" + hashAmt + '&RefNo=' + PaymentID + "&sandbox=" + payment.sandbox + "&controller=" + controllerID)
            .then(res => {

                if (this.state.paymentType === 'ipay88') {
                    if (res.HashValue === "") {
                        this.handleError();
                    } else {
                        document.getElementById("RefNo" + payment.sandbox).value = PaymentID;
                        document.getElementById("Signature" + payment.sandbox).value = res.HashValue;
                        document.getElementById("ipay88Payment" + payment.sandbox).submit();
                    }
                }

            })
            .catch(err => {
                this.handleError();
                console.log(err);
            });
         });
    };
    handleT05Payment = () => {
         
        let data = this.state.data;
        let payment = data.payment;
		//this._setDefaultDetails();
        this._setPopupForPaymentStatus(payment);
        let serverUrl = this.getPaymentDomainUrl(payment);		
        let amt = this.state.total;
        let email=this._getValueByKey('input_email');
        let re = /\S+@\S+\.\S+/;        
        if(!re.test(email))
        {
            email="";
        }
        let mTimesecond = data.res.key;
        let centAmt = Math.round(parseFloat(amt).toFixed(2) * 100);
        let controllerID = this.props.cartStore.menuStore.store.controller;
        let controllerName = this.props.cartStore.menuStore.controller;
        let cId = window.location.protocol + "//" + window.location.host + "/" + controllerName + '--' + mTimesecond + '--' + payment.sandbox;
        // const current = Date.now();
        // let cId = window.location.protocol + "//" + window.location.host + "/" + controllerName + '--' + mTimesecond + '--' + payment.sandbox + '--' + current.toString();
        if (controllerID !== controllerName) {
            cId = cId + '--' + controllerID;
        }        
        cId = btoa(cId);
        localStorage.setItem(`${this.props.cartStore.menuStore.controller}_verifyserver`, serverUrl);
        let paylod = { 'accessToken': payment.appid, 'amountInCents': centAmt, 'customOrderId': cId, 'desc': mTimesecond,'email':email };
        localStorage.setItem(`${this.props.cartStore.menuStore.controller}_partnerTX`, cId);
        localStorage.setItem(`${this.props.cartStore.menuStore.controller}_accessToken`, payment.appid);
        localStorage.setItem(`${this.props.cartStore.menuStore.controller}_sandbox`, (payment.sandbox ? payment.sandbox : 0));
       
        try {
            console.log(payment,'77');
            if (this.state.paymentType === 't05pay') {
				
                if (payment.sandbox === "test") {
                    window.location.href = configParam.fzTestPaymentUrl + "?p=t05&customOrderId=" + cId + "&domianUrl=" + configParam.t05TestPaymentUrl;
                } else {                    
                    window.t05pay(paylod,
                        function(res) {
                            
                            if (res.code === 0) {
                                console.log(res, "t05 error");
                                this.handleError();
                            }
                        });
						this.setState({
							closePopup: [{
							text: this.props.cartStore.menuStore.translation.cancel,
							onPress: () => {
							this.setState({
							promptPopup: false,

							});
							}
							}
							]
						});
						 
                }
            }

        } catch (err) {
            // handle error
            console.log(err, "t05 error");
            this.handleError();
        }
    };
	handleSpotPayment = () => {
         
        let data = this.state.data;
        let payment = data.payment;
        this._setPopupForPaymentStatus(payment);
        let serverUrl = this.getPaymentDomainUrl(payment);
        let amt = this.state.total;
		amt=parseFloat(amt).toFixed(2);
		let controllerID = this.props.cartStore.menuStore.store.controller;
        let customOrderId = data.res.key;
	  localStorage.setItem(`${this.props.cartStore.menuStore.controller}_verifyserver`, serverUrl);
	  this.setState({ promptPopup: false, paymentType: "spot_t05pay", promptLoading: false, activePopup: ""});
        try {

            let thisObj = this;
            const allowedCardNetworks = ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"]; // Enable cards accepted

            const allowedCardAuthMethods = ["PAN_ONLY"]; // CRYPTOGRAM_3DS
            //const billingAddressParameters = { phoneNumberRequired: true };

            const tokenizationSpecification = {
                type: 'PAYMENT_GATEWAY',
                parameters: {
                    "gateway": "2c2p",
                    "gatewayMerchantId": payment.gatewayid,
                }
            };

            const baseCardPaymentMethod = {
                type: 'CARD',
                parameters: {
                    allowedAuthMethods: allowedCardAuthMethods,
                    allowedCardNetworks: allowedCardNetworks,
                    //billingAddressRequired: true,
                    //billingAddressParameters: billingAddressParameters,
                    //cvcRequired: true,
                }
            };
            const cardPaymentMethod = Object.assign(
                {},
                baseCardPaymentMethod,
                {
                    tokenizationSpecification: tokenizationSpecification
                }
            );

            const paymentRequest = {
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [cardPaymentMethod],
                // merchantInfo: { merchantName: payment.appname },
                transactionInfo: {
                    transactionId: customOrderId,
                    currencyCode: payment.currency,
                    //countryCode: payment.country,
                    totalPriceStatus: 'FINAL',
                    totalPrice: amt.toString() // set in checkout()
                }
            };

            console.log("Spot Payment Request", paymentRequest);
            let orderObj=this.spotOrderObject(false);
            window.microapps.requestPayment(paymentRequest).then((paymentData) => {

                thisObj.props._callCartStatusCheck(1);
                orderObj = {
                    ...orderObj,
                    payments: [{
                        id: customOrderId
                    }]
                }
                console.log("Spot Pre Paid OrderObj", orderObj)
                window.microapps.createOrder(orderObj).then(creteOrderRes => {
                    let payload = { controllerId: controllerID, sandbox: payment.sandbox, customOrderId: customOrderId, paymentData: paymentData, amount: amt };
                    console.log("Spot Payload", payload)
                    API.callPostJson('spot-verify', payload)
                        .then(res => {
                            //console.log(res.pingUrl,res);
                            if (res.pingUrl !== "") {
                                 thisObj.props._callPaypalPaymentStatus(res,'spot');
                            }
                            else {
                                alert(thisObj.props.cartStore.menuStore.translation.error, thisObj.props.cartStore.menuStore.translation.in_vaild_error, [
                                    { text: "Close", onPress: () => { } }
                                ]);
                            }
                        })
                        .catch(err => {
                            thisObj.setState({ isPaying: false });
                            console.log(err);
                            alert(err, null, [
                                { text: "Close", onPress: () => { } }
                            ]);
                        });
                    //}
                    console.log(paymentData);
                }).catch(err => {
                    console.log(err);
					thisObj.handleErrorWithMsg(err.message);
                });

            }).catch(function (err) {
                // show error in developer console for debugging

                if (err.code === 1) {
					thisObj.handleErrorWithMsg(thisObj.props.cartStore.menuStore.translation.paypal_payment_msg);
                }
                else if (err.code === 3) {
  					thisObj.handleErrorWithMsg(err.message);
                }
                else {
                    thisObj.handleError();
                }
            });
        } catch (err) {
            console.log(err, "spot error");
            this.handleError();
        }
    };
	handleBWcPayment = () => {
         
        let data = this.state.data;
        let payment = data.payment;
        //this._setPopupForPaymentStatus(payment);
		this.props._callCartStatusCheck(1);
        let serverUrl = this.getPaymentDomainUrl(payment);
        let amt = this.state.total;
		amt=parseFloat(amt).toFixed(2);
		let controllerID = this.props.cartStore.menuStore.store.controller;
        let customOrderId = data.res.key;
	    localStorage.setItem(`${this.props.cartStore.menuStore.controller}_verifyserver`, serverUrl);
	  this.setState({ promptPopup: false, paymentType: "bwc", promptLoading: false, activePopup: ""});
	  let social=this.props.cartStore.menuStore.social;
	  let redeem_code="";
	   if(social && social.rewards && social.rewards[0] && social.rewards[0].redeem_code && social.rewards[0].redeem_code!=='IsMemberDiscount')
		{
			redeem_code=social.rewards[0].redeem_code;
		}
        try {
			let cc = this.props.cartStore.menuStore.controller;
			let bwToken=localStorage.getItem(`${cc}_black_token`);
			let orderPayload={customOrderId:customOrderId,controllerId:controllerID,amount:amt,token:bwToken,redeem_code:redeem_code};
			API.callPostJson('bwc-verify', orderPayload)
				.then(res => {
					//console.log(res.pingUrl,res);
					if (res.pingUrl !== "") {
						 this.props._callPaypalPaymentStatus(res,'bwc');
					}
					else {
						alert(this.props.cartStore.menuStore.translation.error, this.props.cartStore.menuStore.translation.in_vaild_error, [
							{ text: "Close", onPress: () => { } }
						]);
					}
				})
				.catch(err => {
					this.setState({ isPaying: false });
					console.log(err);
					alert(err, null, [
						{ text: "Close", onPress: () => { } }
					]);
				});
               
          
        } catch (err) {
            console.log(err, "bwc error");
            this.handleError();
        }
		
    };
	spotOrderObject = (isPostPaid = false) => {
		
		    let data = this.state.data;
            let payment = data.payment;
			let amt = this.state.total;
		    amt=parseFloat(amt).toFixed(2);
			 let customOrderId = data.res.key;
			 let currency='SGD';
			 if(payment && payment.currency && payment.currency!=="")
			 {
				 currency=payment.currency;
			 }
		    let items = this.props.cartStore.getSpotOrderItems(currency);
            let title=this.props.cartStore.menuStore.store.name;
			if(title==="")
			{
				title=items[0].title;
			}
			
            const orderObj = {
                title: title,
                items: items,
                total: {
                    currency: currency,
                    value: amt.toString()
                },
                status: {
                    type: "PENDING",
                    label: isPostPaid ? "Pay at counter after meal" : ""
                },
                orderId: customOrderId
            };
		return orderObj;
	};
	handleYeahPayPayment = () => {

        let data = this.state.data;
        let payment = data.payment;
        this._setPopupForPaymentStatus(payment);
        let serverUrl = this.getPaymentDomainUrl(payment);
        let amt = this.state.total;
        let orderId = data.res.key;
		let controllerID = this.props.cartStore.menuStore.store.controller;
        let controllerName = this.props.cartStore.menuStore.controller;
		let cUrl=window.location.protocol + "//" +window.location.host+ "/" + controllerName;
        let cId =  controllerID+ '--' + orderId + '--' + payment.sandbox + '--' + controllerID;
    
        cId = btoa(cId);
		
		let callbackUrl = serverUrl + "yeahpay/redirect-url?customOrderId=" + cId;
		let items = this.props.cartStore.getYeahPayOrderItems(payment.currency || "SGD");

		let payload = {
			"orderId": cId,
			"amount": amt.toString(),
			"currency": payment.currency || "SGD",
			"itemInfo": [
				...items
			],
			"callbackUrl": callbackUrl,
			"lang": payment.lang || "en"
		}

        request
            .postJson(serverUrl + "yeahpay/payment-url?controller=" + this.props.cartStore.menuStore.controller +"&sandbox=" + payment.sandbox, {
				...payload
			})
            .then(res => {
				console.log(res);
                if (this.state.paymentType === 'yeahpay') {
                    if (res.data && res.data.url != undefined && res.data.url !== "") {
						window.location.href = res.data.url
                    } else {
                        this.handleError();
                    }
                }

            })
            .catch(err => {
                this.handleError();
                console.log(err);
            });
    };
    handleCashPayment = () => {
        // let data=this.state.data;
        //	  let payment=data.payment;
        let amt = this.props.total;
		this.setState({ isPaying: false });
		let payload = 'payment={"method":"cash","amt":"' + amt + '"}'; 
		if(this.props.cartStore.menuStore.isSpotMethod())
		{
            let orderObj=this.spotOrderObject(true);
            console.log("Spot Post Paid OrderObj", orderObj)
			this.props.cartStore.menuStore.spotOrderObj=orderObj;
			//payload = 'payment={"method":"cash","amt":"' + amt + '","spotOrderId":"'+orderObj.orderId+'"}'; 
		}
		
        this.props._callCartStatusCheck(payload);

    };
    handleError = () => {
       this.handleErrorWithMsg(this.props.cartStore.menuStore.translation.in_vaild_error);
    };
	handleErrorWithMsg = (title) => {
        this.props._callCartStatusCheck(2);
        this.setState({ isPaying: false, promptPopup: false, paymentType: "", promptLoading: false, activePopup: "" },()=>{
			alert(title, null, [{
				text: this.props.cartStore.menuStore.translation.close,
				onPress: () => {
					this.props.cartStore.menuStore.showPaymentOption = false;
				}
			}]);
		});
    };
    onOpened = () => {
        this.setState({ isPaying: true, paymentType: 'cash' });
        this.props.cartStore.menuStore.showPaymentOption = false;
    };
    onStripeToken = (token) => {
        //console.log(token,'stripe');
        this.props._callCartStatusCheck(1);
        let amt = this.props.total.replace('.', '');
        request
            .get(
                window.location.protocol +
                "//" +
                window.location.host +
                "/stripe.php?token=" + token.id + "&pk=" + this.props.cartStore.menuStore.stripeKey + "&amt=" + amt
            )
            .then(res => {
                //console.log(res,'stripe');
                res.method = "stripe";
                res.amt = this.props.total;

                //const payload = 'payment={"method":"stripe","paymentAmount":"'+this.props.total+'","transactionId":"'+res.id+'"}';
                const payload = 'payment=' + encodeURI(JSON.stringify(res));

                this.props.cartStore
                    .checkout(null, payload)
                    .then(res => {

                        /* this.setState({ isPaying: false,orderStep:1 }, () => {
						 
					   	
						 });*/
                        this.props.cartStore.menuStore.showPaymentOption = false;
                        this.props.cartStore.tabHandler("order");
                        this.props._callCartStatusCheck(res);
                    });
            })
            .catch(err => {
                this.setState({ isPaying: false });
                err = JSON.parse(err);
                alert(err.msg.message, null, [
                    { text: "Close", onPress: () => {} }
                ]);
            });
    }
    payPalSuccess = (paypalRes) => {
        this.props.cartStore.menuStore.showPaymentOption = false;
        this.setState({ isVisiableReciept: false });
		//paypalRes={paid: true, cancelled: false, payerID: "LT5N24UTEAG8N", paymentID: "PAYID-MB2ZNSA7X3632903H6470522", paymentToken:"EC-557940672L509123K", returnUrl: "https://www.sandbox.paypal.com/?paymentId=PAY-47J75876PA321622TLESPATA&token=EC-557940672L509123K&PayerID=H8S4CU73PFRAG"}
      // console.log(paypalRes,'paypalRes');
        if (paypalRes === 'error') {
            alert(
                this.props.cartStore.menuStore.translation.payment_status,
                this.props.cartStore.menuStore.translation.payment_msg,
                [
                {
                text: this.props.cartStore.menuStore.translation.close
                }
                ]
                );
        }
       else if (paypalRes !== 'cancel' && paypalRes.paid && paypalRes.paid===true) {
            let data = this.state.data;
            let customOrderId = data.res.key;
            let sandbox=data.payment.sandbox;
            this.props._callCartStatusCheck(1);
            let controllerID = this.props.cartStore.menuStore.store.controller;		
          let payload="paid="+paypalRes.paid+"&cancelled="+paypalRes.cancelled+"&payerID="+paypalRes.payerID+"&paymentID="+paypalRes.paymentID+"&paymentToken="+paypalRes.paymentToken+"&customOrderId="+customOrderId+"&controllerId="+controllerID+"&sandbox="+sandbox;
            API.addOrderByPost('paypal-order',payload)
                .then(res => {
                    //console.log(res.pingUrl,res);
                   if(res.pingUrl!=="")
                   {
                       res.refno=customOrderId;
                    this.props._callPaypalPaymentStatus(res,'paypal');
                   }
                   else{
                    alert(this.props.cartStore.menuStore.translation.error, this.props.cartStore.menuStore.translation.in_vaild_error, [
                        { text: "Close", onPress: () => {} }
                    ]);
                   }
                })
                .catch(err => {
                    this.setState({ isPaying: false });
                    console.log(err);
                    //err = JSON.parse(err);
                    alert(err, null, [
                        { text: "Close", onPress: () => {} }
                    ]);
                });
        } else {
            alert(
                this.props.cartStore.menuStore.translation.payment_status,
                this.props.cartStore.menuStore.translation.paypal_payment_msg, [{
                    text: this.props.cartStore.menuStore.translation.close
                }]
            );
        }
    };
	callOnlinePayment=(payment)=>{
		this.props.cartStore.menuStore.showPaymentOption = false;
         let data={...this.state.data};
		 data.payment=payment;
		this.setState({ data:data, paymentType: payment.gateway, activePopup: '' }, () => {
			if(this.props.cartStore.menuStore.layout===4 || payment.gateway==='cash')
			{
				this.callPaymentServer();
			}
			else
			{
				let dAll = {};
				dAll.payment = payment;
				this.handlePaymentRequest(dAll);
			}
			
		});
		
	};
	callPaymentServer=()=>{
		this.setState({ isVisiableReciept: false },()=>{
			if (this.state.paymentType === 'eghl') {
			this.handleEGHLPayment();
			} else if (this.state.paymentType === 'ipay88') {
			this.handleIPay88Payment();

			} else if (this.state.paymentType === 't05pay') {
			this.handleT05Payment();
			} 
			else if (this.state.paymentType === 'alipay') {
			this.handleAliPayment();
			}
			else if (this.state.paymentType === 'spot_t05pay') {
			this.handleSpotPayment();
			}
			else if (this.state.paymentType === 'dbs') {
			this.handleDBAPayment();
			}
			else if (this.state.paymentType === 'adyen') {
			this.handleAdyenPayment();
			}
			else if (this.state.paymentType === 'bwc') {
			this.handleBWcPayment();
			}
            else if (this.state.paymentType === 'yeahpay') {
                this.handleYeahPayPayment();
            }
			else
			{
			  this.handleCashPayment();	
			}
		});
		
	};
    btnGenerator = (payment) => {
        let gateway = payment.gateway;
        switch (gateway) {

            case 'grab':
                return ( <
                    div key = "grab"
                    onClick = {
                        () => {
                            this.setState({ paymentType: 'grab' });
                            this.props.cartStore.menuStore.showPaymentOption = false;
                            this.props._callCartStatusCheck(1);
                            request
                                .get(
                                    window.location.protocol + "//" + window.location.host + "/grab.php?price=" + this.props.total + "&controller=" + this.props.cartStore.menuStore.controller + "&client_id=" + payment.appid + "&sandbox=" + payment.sandbox
                                    //window.location.protocol +"//upwork.foodzaps.com/grab.php?price=" +this.props.total+"&controller="+this.props.cartStore.menuStore.controller+"&client_id="+payment.appid+"&sandbox="+payment.sandbox
                                )
                                .then(res => {

                                    if (res.msg.indexOf("https://") > -1) {
                                        localStorage.setItem(`${this.props.cartStore.menuStore.controller}_partnerTX`, res.partnerTxID);
                                        window.location.href = res.msg;
                                        return null;
                                    } else {
                                        alert(this.props.cartStore.menuStore.translation.error, this.props.cartStore.menuStore.translation.in_vaild_error, [
                                            { text: "Close", onPress: () => {} }
                                        ]);
                                    }
                                })
                                .catch(err => {
                                    this.props._callCartStatusCheck(2);
                                    this.setState({ isPaying: false });
                                    err = JSON.parse(err);
                                    alert(err.msg.message, null, [{
                                        text: "Close",
                                        onPress: () => {
                                            this.props.cartStore.menuStore.showPaymentOption = true;
                                        }
                                    }]);
                                });

                        }
                    }
                    className = "liInner" > < img src = { payment.icon }
                    alt = { payment.name }
                    /><span>{payment.name}</span > < /div>
                );
            case 'stripe':
                return ( <
                    div key = "stripe"
                    className = "liInner stripe" >
                    <
                    StripeCheckout name = { this.props.cartStore.menuStore.store.name } // the pop-in header title
                    //description="Big Data Stuff" // the pop-in header subtitle
                    image = { this.props.cartStore.menuStore.store.logo } // the pop-in header image (default none)
                    ComponentClass = "div"
                    //panelLabel="Pay with Stripe" // prepended to the amount in the bottom pay button
                    amount = { parseFloat(this.props.total).toFixed(2) * 100 } // cents
                    currency = 'SGD'
                    stripeKey = { payment.appid }
                    allowRememberMe // "Remember Me" option (default true)
                    token = { this.onStripeToken } // submit callback
                    opened = { this.onOpened }
                    reconfigureOnUpdate = { true }

                    >
                    <
                    img src = { payment.icon }
                    alt = { payment.name }
                    /><span>{payment.name}</span >
                    <
                    /StripeCheckout> < /
                    div > );           
            case 'ipay88':
                return ( <
                    div key = "ipay88"
                    onClick = {
                        () => {
                            
								this.callOnlinePayment(payment);

                        }
                    }
                    className = "liInner" > {this.props.cartStore.menuStore.layout===4 ? <span className={gateway}>&nbsp;</span>:< img src = { payment.icon }
                    alt = { payment.name }
                    />}<span>{payment.name}</span > < /div>
                );
            case 'eghl':

                return (

                    <
                    div key = "eghl"
                    onClick = {
                        () => {
                            
                                this.callOnlinePayment(payment);

                        }
                    }
                    className = "liInner" > < img src = { payment.icon }
                    alt = { payment.name }
                    /><span>{payment.name}</span > < /div>
                );
            default:
                return ( <
                    div key = { gateway }
                    onClick = {
                        () => {
                            //this.openSinglePaymentGateway(payment);
							this.callOnlinePayment(payment);
                        }
                    }
                    className = "liInner" ><Fragment>< img src = { payment.icon} alt = { payment.name }
                    />{this.props.cartStore.menuStore.layout===4 && gateway==='cash' ?<span>Pay at Counter</span>:<span>{payment.name}</span>}</Fragment> 
					</div>
                );
        }
    };
    _renderPaymentButtons = () => {
        const list = [];
        //console.log(this.props.cartStore.menuStore.paymentGateways);
        _.each(this.props.cartStore.menuStore.paymentGateways, (e, i, l) => {
            list.push(this.btnGenerator(e));
        });

        return list;
    };

    _renderPaymentHtml = () => {
        const list = [];
        //console.log(this.props.cartStore.menuStore.paymentGateways);
        _.each(this.props.cartStore.menuStore.paymentGateways, (e, i, l) => {
            if (e.gateway === 'eghl') {
                list.push(this.renderEghlPaymentGateway(e));
            } else if (e.gateway === 'ipay88') {
                list.push(this.renderIpay88(e));
            }
        });

        return list;
    };

    _renderPromptPopup = () => {
        return ( <
            PromptPopup visibility = { this.state.promptPopup }
            title = { this.state.promptTitle }
            closePopup = { this.state.closePopup }
            content = { this.state.promptContent }
            isLoading = { this.state.promptLoading }
			 cartStore={this.props.cartStore}
            />
        );
    };

    renderEghlPaymentGateway(payment) {
        let serverUrl = this.getPaymentDomainUrl(payment);
        let cUrl = window.location.href;

        let returnUrl = serverUrl + "eghl-redirect?c=" + cUrl;
        let eghlUrl = configParam.eghlPaymentUrl;
        if (payment.sandbox === "test") {
            eghlUrl = configParam.fzTestPaymentUrl + "?p=eghl";
        }
        
        return ( <
            Modal visible = { this.state.activePopup === 'eghl' ? true : false }
            transparent = { true }
            maskClosable = { false }
            animationType = "slide-up"
            closable = { false }
            className = "App table_question pineappleModal"
            key = "eghlModal" >

            <
            div className = "row heading" > < span > { this.props.cartStore.menuStore.translation.payment_preview } < /span></div >
            <
            div className = "row" >
            <
            form name = "eghlform"
            id = { "eghlform" + payment.sandbox }
            method = "post"
            action = { eghlUrl } >
            <
            input type = "hidden"
            name = "TransactionType"
            value = "SALE"
            readOnly / >
            <
            input type = "hidden"
            name = "PymtMethod"
            value = "ANY"
            readOnly / >
            <
            input type = "hidden"
            name = "ServiceID"
            value = { payment.appid }
            readOnly / >
            <
            input type = "hidden"
            name = "PaymentID"
            id = { "PaymentID" + payment.sandbox }
            value = ""
            readOnly / >
            <
            input type = "hidden"
            name = "OrderNumber"
            id = { "OrderNumber" + payment.sandbox }
            value = ""
            readOnly / >
            <
            input type = "hidden"
            name = "PaymentDesc"
            value = "QR food ordering"
            readOnly / >
            <
            input type = "hidden"
            name = "MerchantName"
            value = "Foodz App"
            readOnly / >
            <
            input type = "hidden"
            name = "MerchantReturnURL"
            value = { returnUrl }
            readOnly / >
            <
            input type = "hidden"
            name = "Amount"
            value = { this.state.total }
            readOnly / >
            <
            input type = "hidden"
            name = "CurrencyCode"
            value = { payment.currency }
            readOnly / >
            <
            input type = "hidden"
            name = "CustIP"
            id = { "CustIPeGHL" + payment.sandbox }
            value = ""
            readOnly / >
            <
            input type = "hidden"
            name = "HashValue"
            id = { "HashValue" + payment.sandbox }
            value = ""
            readOnly / >
            <
            input type = "hidden"
            name = "MerchantTermsURL"
            value = {configParam.eghlMerchantTermUrl}
            readOnly / >
            <
            input type = "hidden"
            name = "LanguageCode"
            value = "en"
            readOnly / >
            <
            input type = "hidden"
            name = "PageTimeout"
            value = { PageTimeout }
            readOnly / >
            <
            input type = "hidden"
            name = "CustName"
            value = { this.state.name }
            readOnly / >
            <
            input type = "hidden"
            name = "CustEmail"
            value = { this.state.email }
            readOnly / >
            <
            input type = "hidden"
            name = "CustPhone"
            value = { this.state.contact }
            readOnly / > < div > { this.props.cartStore.menuStore.translation.checking_order_with_outlet } <
            /div> <
            div className = { this.state.errors === "" ? "" : "hideDiv" } >
            <
            img src = {configParam.fzLoadingImage}
            alt = "loading..."
            style = {
                { width: "50px" }
            }
            /> </div>

            <div className = { this.state.errors === "" ? "hideDiv" : "error" } > { this.state.errors } </div>						 
            </form> 
            </div> </Modal>
        );

    };

    renderIpay88(payment) {
        let serverUrl = this.getPaymentDomainUrl(payment);
		let controllerID = this.props.cartStore.menuStore.store.controller;
        let cUrl = window.location.href + "-and-" + payment.sandbox+"-and-"+controllerID;

        let backendUrl = serverUrl + "ipay88-backend?c=" + cUrl;
        let returnUrl = serverUrl + "ipay88-redirect?c=" + cUrl;
        let signHash = "";
        let ipayUrl = configParam.ipay88PaymentUrl;
        if (payment.sandbox === "test") {
            ipayUrl = configParam.fzTestPaymentUrl + "?p=ipay88";
        }
        return ( <
            Modal visible = { this.state.activePopup === 'ipay88' ? true : false }
            transparent = { true }
            maskClosable = { false }
            animationType = "slide-up"
            closable = { false }
            className = "App table_question pineappleModal"
            key = "ipayModal" 
			footer={this._closePaymentPopup()}
			>
            <
            div className = "row heading" > < span > { this.props.cartStore.menuStore.translation.processing_payment +" IPay88" } < /span></div >
            <
            div className = "row" >
            <
            form method = "post"
            name = "ipay88Payment"
            id = { "ipay88Payment" + payment.sandbox }
            action = { ipayUrl } >
            <
            input type = "hidden"
            name = "MerchantCode"
            value = { payment.appid }
            readOnly / >
            <
            input type = "hidden"
            name = "PaymentID"
            id = "IpayPaymentID"
            value = ""
            readOnly / >
            <
            input type = "hidden"
            name = "RefNo"
            id = { "RefNo" + payment.sandbox }
            value = ""
            readOnly / >
            <
            input type = "hidden"
            name = "Amount"
            value = { this.state.total.toLocaleString('en') }
            readOnly / >
            <
            input type = "hidden"
            name = "Currency"
            value = { payment.currency }
            readOnly / >
            <
            input type = "hidden"
            name = "ProdDesc"
            value = "QR food ordering"
            readOnly / >
            <
            input type = "hidden"
            name = "SignatureType"
            value = "SHA256" / >
            <
            input type = "hidden"
            name = "Signature"
            id = { "Signature" + payment.sandbox }
            value = { signHash }
            readOnly / >
            <
            input type = "hidden"
            name = "ResponseURL"
            value = { returnUrl }
            readOnly / >
            <
            input type = "hidden"
            name = "BackendURL"
            value = { backendUrl }
            readOnly / >

            <
            input type = "hidden"
            name = "UserName"
            value = { this.state.name }
            readOnly / >
            <
            input type = "hidden"
            name = "UserEmail"
            value = { this.state.email }
            readOnly / >
            <
            input type = "hidden"
            name = "UserContact"
            value = { this.state.contact }
            readOnly / >

            <
            div className = { this.state.errors === "" ? "" : "hideDiv" } >
            <
            img src = {configParam.fzLoadingImage}
            alt = "loading..."
            style = {
                { width: "50px" }
            }
            /> < /
            div > <
            div className = { this.state.errors === "" ? "hideDiv" : "error" } > { this.state.errors } <
            /div>

            <
            /form> < /
            div > <
            /Modal>
        );
    };

    _renderReciept() {
        let data = this.state.data;
        let respo = data.res;
        let payment = data.payment;
        let receipt=JSON.parse(respo.receipt);
        let env ="sandbox";
        let client = {
            sandbox: "",
            production: "",
        };
        let options={};
		let isOutLetItems=this.props.cartStore.checkTotoitoastOutlet();
		let outLetCls='';
		let totalamt=receipt.total?receipt.total.toFixed(2):"0.00";
		if(totalamt<0)
		{
			totalamt="0.00";
		}
		if(!isOutLetItems)
		{
			outLetCls='outLetCls';
		}
        if(payment)
        {
             env = payment.sandbox === "1" ? "sandbox" : "production";
             client = {
                    sandbox: payment.appid,
                    production: payment.appid,
                };

                let controllerName = this.props.cartStore.menuStore.controller;
                let redirectUrl = window.location.protocol + "//" + window.location.host+"/"+controllerName;
                let controller=this.props.cartStore.menuStore.store.controller;         
                options.transactions=[
                  { 
                    amount:
                    {
                    total: this.props.total,
                    currency: payment.currency
                    },
                    description: redirectUrl,
                    custom: controller+"--"+payment.sandbox,
                    invoice_number: data.res.key
                  }
                ];
        }      
//        let deliveryType=this.props.cartStore.menuStore.getDeliveryType();
		let isDelievryOrPickup=this.props.cartStore.menuStore.checkIsPickUpOrDelivery();
        return ( <
            Modal visible = { this.state.isVisiableReciept }
            transparent = { true }
            maskClosable = { false }
            animationType = "slide-up"
            closable = { false }
            className = "App table_question paymentReceipt"
            key = "recieptModal"
            title = { <div className="headingicons"><span className="backbtnhead"><Icon key="1" type="left" style={{ fontSize: '25px', color: '#fff' }}  
					onClick={()=>{
						this.setState({ isVisiableReciept: false,activePopup:"" });
                    this.props._callCartStatusCheck(2);
					}}
					/></span><span className="headingTitle">{this.props.cartStore.menuStore.translation.payment_preview}</span><span className="closebtnhead"><Icon key="1" type="home" style={{ fontSize: '25px', color: '#fff' }}  
					onClick={()=>{
						this.setState({ isVisiableReciept: false });
                        this.props._callCartStatusCheck(2);
					}}
					/></span></div> }

            >
            <div className = "row borderTop" >
            
			{this.props.cartStore.menuStore.layout===4?(
			<Fragment>
			<div className = {"paymentwrapper "+outLetCls}>
			{!isDelievryOrPickup && (<div className="tbaleclss"><span> <strong>Table # {this.props.cartStore.menuStore.getRealTagValue()}</strong></span><span className="restAddress">{this.props.cartStore.menuStore.store.name}</span></div>)}
			<div className="itempreview">			
			<div className="itemrow itemrowheading"> <div>Qty</div><div>Order</div> <div>Amount</div> </div>
			{this.renderItemPreview()}	
			</div>
			  
			 <div className="paymentHistory">
				<Collapse>
				<Panel header="Payment Summary" key="1">
				 <div className="priceInfo">
			         
					  <div className="priceRow"><span>Sub Total</span><span>{this.props.cartStore.menuStore.currencySymbol} {receipt.sub?receipt.sub.toFixed(2):0.00}</span></div>
				      {receipt.taxBefore && receipt.taxBefore.toFixed(2)>0 ?(<div className="priceRow"><span>{receipt.taxBefore_name?receipt.taxBefore_name:"Service Charge"}</span><span>{this.props.cartStore.menuStore.currencySymbol} {receipt.taxBefore?receipt.taxBefore.toFixed(2):0.00}</span></div>):('')}
					  <div className="priceRow"><span>{receipt.tax1_name?receipt.tax1_name:"GST"}</span><span>{this.props.cartStore.menuStore.currencySymbol} {receipt.tax1?receipt.tax1.toFixed(2):0.00}</span></div>
					  <div className="priceRow"><span>{receipt.discount_name?receipt.discount_name:"Discount"}</span><span>-{this.props.cartStore.menuStore.currencySymbol} {receipt.discount?Math.abs(receipt.discount.toFixed(2)):0.00}</span></div>
					 {receipt.discount_by_value && ( <div className="priceRow"><span>{receipt.discount_by_value_name?receipt.discount_by_value_name:"Discount"}</span><span>-{this.props.cartStore.menuStore.currencySymbol} {receipt.discount_by_value?Math.abs(receipt.discount_by_value.toFixed(2)):0.00}</span></div>)}
					  <div className="priceRow"><span className="priceLabelTotal">{receipt.total_name}</span><span className="priceavlue">{this.props.cartStore.menuStore.currencySymbol} {totalamt}</span></div>
			         </div>	
				</Panel>				
				</Collapse>			
                <div className={isDelievryOrPickup?"totalPaymentrow":"lastPaymentRow totalPaymentrow"}><span className="priceLabelTotal">Total Payment</span><span className="priceavlue">{this.props.cartStore.menuStore.currencySymbol} {receipt.total?receipt.total.toFixed(2):0.00}</span></div>
				
				<AddressInfo page="payment" />
				
			   <PowerBy/>
			  </div>
			 
			</div>
			
			</Fragment>
			):(
			<div className = {"paymentwrapper "+outLetCls}
            dangerouslySetInnerHTML = {
                { __html: this.props.cartStore.menuStore.htmlDecode(respo.lastMessage) }
            }
            /> 
			)}
			
			<div className = "btnSetP" >
            <Button className = "class-btn option-list greytext"
            onClick = {
                () => {
                    this.setState({ isVisiableReciept: false });
                    this.props._callCartStatusCheck(2);
                 }
            } > { this.props.cartStore.menuStore.translation.cancel } < /Button> 
           {this.state.paymentType === 'paypal'?(
            <div className = "paypalbtn" >   
            <PaypalCheckout id = "paypalBtn"
            payPalSuccess = { this.payPalSuccess }
            env = { env }
            total = { parseFloat(this.props.total) }
			currency= { payment.currency }
            client = { client }
		//	customOrderId = {data.res.key}
            options={options}
            /></div>
           ):(
           <Button className = "class-btn option-list"
            onClick = {
                () => {
					if(this.props.cartStore.menuStore.layout===4 && this.props.cartStore.menuStore.paymentGateways.length>1)
					{
						this.props._callCartStatusCheck(2);
						this.props.cartStore.menuStore.showPaymentOption=true;
					}
					else if(this.props.cartStore.menuStore.layout===4 && this.props.cartStore.menuStore.paymentGateways.length===1)
					{
						this.callOnlinePayment(this.props.cartStore.menuStore.paymentGateways[0]);
					}
					else
					{
						this.callPaymentServer();
						
					}
					if(this.props.cartStore.menuStore.layout!==4)
					{
						this.setState({ isVisiableReciept: false });
					}                   
                    //this.props._callCartStatusCheck(1);
                }
            } > { this.props.cartStore.menuStore.paymentGateways.length>1 ? this.props.cartStore.menuStore.translation.pay_now: this.props.cartStore.menuStore.translation.proceed_now} </Button>
            )}
            </div> </div> 
            </Modal>
        );
    };
	_renderAdyenGateway() {       
        let data = this.state.data;
        let respo = data.res;	
        return ( <Modal visible = { this.state.activePopup==='adyen' }
            transparent = { true }
            maskClosable = { false }
            animationType = "slide-up"
            closable = { false }
            className = "App table_question paymentReceipt adyentpayment"
            key = "adyenModal" 
			title = {<div className="headingicons"><span className="backbtnhead"><Icon key="1" type="left" style={{ fontSize: '25px', color: '#fff' }}  
					onClick={()=>{
						console.log(5555,'calleddd');
						this.setState({ isVisiableReciept: false,activePopup:"" });
                        //this.props._callCartStatusCheck(2);
					}}
					/></span><span className="headingTitle">{this.props.cartStore.menuStore.translation.payment_preview}</span><span className="closebtnhead"></span></div> }
            >
	           <p>{this.state.adyenContent}</p>
	           <div id="dropin-container"></div>
			   <div className = {"paymentwrapper adyenpayment"}
            dangerouslySetInnerHTML = {
                { __html: this.props.cartStore.menuStore.htmlDecode(respo.lastMessage) }
            }
            /> 
            </Modal>
        );
    };
    _defaultClose = closeText => {
        const { cartStore } = this.props;
        return [{
            text: closeText ?
                closeText : cartStore.menuStore.translation.close,
            onPress: () => {
                this.props.cartStore.menuStore.showPaymentOption = false;
            }
        }];
    };
	renderItemPreview()
	{
      let {cartStore}=this.props;
	  return _.map(cartStore.entries, function(entry,ind){
			return (
			  <Fragment key={entry.item.id+'-'+ind}>
				<ItemPreview entry={entry} cartStore={cartStore} />
			  </Fragment>
			);
		});
	} ;
    render() {
		let bTile="";
		if(this.props.cartStore.menuStore.isBWCMethod())
		{
			 let social=this.props.cartStore.menuStore.social;
			 let credits_balance=0;
			 if(social.credits_balance)
			 {
				 credits_balance=social.credits_balance;
			 }
			bTile=this.props.cartStore.menuStore.bwcGateway.name+" Balance: S$"+credits_balance;
		}
        return ( <
            Fragment >
            <Modal visible = { this.props.visible }
            transparent = { true }
            maskClosable = { false }
            animationType = "slide-up"
            closable = { false }
            className = "App table_question postqa payments"
            wrapClassName = "qa-wrap"
            afterClose = {
                () => { localStorage.removeItem("is_opened_post_question"); }
            }
            title = {this.props.cartStore.menuStore.layout===4?<div><span className="paymentHeading">Payment</span> <span className="closeBtn"
			onClick={()=>{
				this.props.cartStore.menuStore.showPaymentOption = false;
			}}
			></span></div>:"Select Payment"}
            footer = { this.props.cartStore.menuStore.layout===4?'':this._defaultClose() } 
		    >
     		  <div className="paymentBtns">{ this._renderPaymentButtons() } </div>
				  {this.props.cartStore.menuStore.isBWCMethod() &&(<div className="blanceInfo">{bTile}</div>)}
			</Modal> { this._renderPaymentHtml() } { this._renderReciept() } { this._renderPromptPopup() }{this._renderAdyenGateway()} </Fragment >
        );
    }
}

const ItemPreview = observer(({ entry, cartStore }) => {
  let item = entry.item;
  
  // let itemNotes = [];
  // if (entry.notes.length > 0) {
    // _.each(entry.notes, (note, index) => {
      // if (note !== '') { itemNotes.push(note); }
    // });
  // }
  // itemNotes = itemNotes.join(', ');
 //let itemNotes=cartStore.getAddonsNames(entry.addons);
 let qty=entry.quantity;
 //let priceAmt=cartStore.entryTotal(entry);
 let totalAmt=cartStore.entryTotal(entry);
 let {priceAmt}=cartStore.calculateRewardDiscoutPrice(entry.reward,totalAmt);
let reward=	entry.reward;
let rewardDiv="";
if(reward && reward.redeem_code && reward.redeem_code!=="")
{
	rewardDiv=<p className="disNameBg">{reward.redeem_name}</p>;
}
  return (
	  <div className="itemrow"> 
	     <div>{qty}</div>
		 <div>
		 <div><p>{item.name}</p>{rewardDiv}</div>
			 {entry.addons && entry.addons.length>0 && ( <RenderAddon addonSlected={entry.addons} />)}
		    <RenderNotes entry={entry} />
				
		 </div>
         {/*actualPrice==priceAmt?(<div><strike className="strikeprice">{cartStore.menuStore.currencySymbol+parseFloat(actualPrice).toFixed(2)}</strike>{cartStore.menuStore.currencySymbol+parseFloat(priceAmt).toFixed(2)}</div>):(*/}
				<div>{cartStore.menuStore.currencySymbol+' '+parseFloat(priceAmt).toFixed(2)}</div>		 
	   </div> 
     );
});
export default PaymentOption;